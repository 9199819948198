import React, { Component } from 'react';
import { connect } from 'react-redux';
import { store } from '../../../index'
import { Header, Button, Dropdown } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import {
    CarouselProvider,
    Slide,
    ButtonBack,
    ButtonNext,
    Image
} from 'pure-react-carousel';
import { storeSequence, imageStep, changeStore, updateActiveSeries, startLoading, stopLoading } from '../../actions';
import { Slider } from 'pure-react-carousel';
import './style.css';

class AutoViewModal extends Component {

    state = {
        point: {},
        startPoint: {},
        direction: null,
        renderedPlan: false,

        isBrigthnessLevel: false,
        defaultBrightness: 100,
        defaultContrast: 100,
        brightness: 100,
        contrast: 100,
        opacity: 0,
        lastSlide: false,
        currentSlide: 0,
        localSerieId: {
            id: [],
            images: [],
            imgLength: false,
        },
        newActiveSequence: 0,
    }

    componentDidMount() {
        this.setState({ localSerieId: this.props.localSerieId, newActiveSequence: this.props.scanner.activeSequence })
    }

    componentDidUpdate(prevProps) {
        // dropdown functionality
        if (prevProps.scanner.brightness !== this.props.scanner.brightness) {
            this.setState({
                brightness: this.props.scanner.brightness,
                contrast: this.props.scanner.contrast,
                opacity: this.props.scanner.opacity
            })
        }

        if (prevProps.scanner.newLocalSerieId !== this.props.scanner.newLocalSerieId) {
            this.setState({ localSerieId: this.props.scanner.newLocalSerieId })
        }
        if (prevProps.scanner.activeSequence !== this.props.scanner.activeSequence) {

            this.setState({ newActiveSequence: this.props.scanner.activeSequence })
        }

        let array = this.state.localSerieId;

        let i = prevProps.scanner.sequenceNumber;
        let id = this.props.scanner.activeSerie.id;

        if (i !== undefined && id !== undefined) {
            const r = array.id.findIndex(el => el === id);
            if (r === -1 && prevProps.scanner.enableStartScan) {
                array.id.push(id);
                array.images.push(this.props.scanner.localSerie.images);
                prevProps.scannerAction.changeStore('activeSequence', array.images.length - 1);
                i++;
                prevProps.scannerAction.storeSequence(i, id);
                this.setState({
                    localSerieId: array,
                    brightness: 100,
                    contrast: 100,
                    opacity: 0,
                })
            }
        }
    }

    changeBrightness = (e) => {
        if (this.state.isBrigthnessLevel) {
            const xAxisMove = this.state.startPoint.startXPos - e.clientX;
            const yAxisMove = this.state.startPoint.startYPos - e.clientY;
            let brightness = this.state.defaultBrightness - yAxisMove * 2;
            let contrast = this.state.defaultBrightness - xAxisMove * 2;
            let opacity = 0 - yAxisMove / 2;
            this.setState({ brightness, contrast, opacity })
        }
        e.preventDefault();
    }

    onMouseMove = e => {
        const items = e.nativeEvent.target.closest(".autoview-container").querySelectorAll('img');
        this.changeBrightness(e, items)
    }

    onMouseDown = e => {
        e.preventDefault()
        this.setState({
            isBrigthnessLevel: true,
            startPoint: {
                startXPos: e.clientX,
                startYPos: e.clientY
            }
        })
    }

    onMouseUp = () => {
        this.setState({ isBrigthnessLevel: false })
    }

    handleChange = (e, { name, value }) => {
        let imagesIndex = this.state.localSerieId.id.findIndex(el => el === value);
        this.setState({ newActiveSequence: imagesIndex })
    }

    renderSeriesImages = () => {
        const activeSequence = this.state.newActiveSequence;
        const images = this.props.localSerieId.images[activeSequence];
        const sequence = this.props.scanner.sequenceState.map(el => el);
        sequence.shift()
        if (images === undefined) { return; } else
            return (
                <CarouselProvider
                    currentSlide={this.props.scanner.imageStep}
                    dragEnabled={false}
                    touchEnabled={false}
                    naturalSlideWidth={50}
                    naturalSlideHeight={50}
                    totalSlides={images.length}
                >
                    <Slider classNameAnimation='null' >
                        {images.map((image, index) => (
                            <Slide index={image.id} key={index}>
                                <Image
                                    src={image}
                                    draggable={false}
                                    style={{ filter: `brightness(${this.state.brightness}%) contrast(${this.state.contrast}%)` }}
                                />
                                <div
                                    className='slide-white-overlay'
                                    style={{ filter: `opacity(${this.state.opacity}%)` }}
                                />
                            </Slide>
                        ))}
                    </Slider>
                    <div className='autoview-button-block'>
                        <ButtonBack
                            grey='grey'
                            disabled={(this.props.scanner.imageStep < images.length - 1) && !this.props.scanner.imageStopped ? true : null}
                            onClick={e => { this.props.scannerAction.changeStore('imageStep', e._targetInst.return.stateNode.props.currentSlide - 1); }}>
                            Prior
                        </ButtonBack>
                        <ButtonNext
                            grey='grey'
                            disabled={(this.props.scanner.imageStep < images.length - 1) && !this.props.scanner.imageStopped ? true : null}
                            onClick={e => { this.props.scannerAction.changeStore('imageStep', e._targetInst.return.stateNode.props.currentSlide + 1); }}>
                            Next
                        </ButtonNext>
                        <Dropdown
                            disabled={(sequence.length > 0) ? false : true}
                            compact
                            selection
                            options={sequence}
                            value={sequence[activeSequence].value}
                            onChange={this.handleChange}
                        />
                    </div>
                </CarouselProvider>
            );
    };

    renderEmptyImage = () => {
        return (
            <div>
                <div className="empty-image" />
                <div className='autoview-button-block empty'>
                    <Button content="Prior" />
                    <Button content="Next" />
                    <Dropdown
                        grey='grey'
                        disabled
                        compact
                        selection
                        options={[{ text: '1', value: 0 }]}
                    />
                </div>
            </div>
        );
    };

    render() {
        const {
            scan: { isRunning, cancelled },
            files: { images }
        } = this.props.scanner;

        return (
            <div className="autoview-container"
                // onContextMenu={this.onMouseDown}
                onMouseDown={this.onMouseDown}
                onMouseUp={this.onMouseUp}
                onMouseMove={this.onMouseMove}
            >
                {(images.length && !isRunning && !cancelled)
                    ? this.renderSeriesImages()
                    : this.renderEmptyImage()}
            </div>
        );
    }
}

const mapStateToProps = ({ scanner }) => ({ scanner });

const mapDispatchToProps = dispatch => ({
    scannerAction: bindActionCreators(
        {
            storeSequence,
            imageStep,
            changeStore,
            updateActiveSeries
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoViewModal);
