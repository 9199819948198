import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header, Button, Dropdown, FeedSummary } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import {
  CarouselProvider,
  Slide,
  ButtonBack,
  ButtonNext,
  Image
} from 'pure-react-carousel';
import { storeSequence, imageStep, changeStore, updateActiveSeries, cancelScan } from '../../actions';
import { Slider } from 'pure-react-carousel';
import './style.css';

class AutoView extends Component {

  state = {
    point: {},
    startPoint: {},
    direction: null,
    renderedPlan: false,

    isBrigthnessLevel: false,
    defaultBrightness: 100,
    defaultContrast: 100,
    brightness: 100,
    contrast: 100,
    opacity: 0,
    lastSlide: false,
    currentSlide: 0,
    localSerieId: {
      id: [],
      images: [],
    },
    newActiveSequence: 0,
    additionalTimer: '',
  }

  componentDidUpdate(prevProps) {
    // dropdown functionality
    if (prevProps.scanner.brightness !== this.props.scanner.brightness) {
      this.setState({
        brightness: this.props.scanner.brightness,
        contrast: this.props.scanner.contrast,
        opacity: this.props.scanner.opacity
      })
    }

    if (prevProps.scanner.newLocalSerieId !== this.props.scanner.newLocalSerieId) {
      this.setState({ localSerieId: this.props.scanner.newLocalSerieId })
    }
    if (prevProps.scanner.activeSequence !== this.props.scanner.activeSequence) {
      this.setState({ newActiveSequence: this.props.scanner.activeSequence })
    }

    let { localSerieId } = this.state

    let i = prevProps.scanner.sequenceNumber;
    let id = this.props.scanner.activeSerie.id;
    if (i !== undefined && id !== undefined) {
      const r = localSerieId.id.findIndex(el => el === id);
      if (r === -1 && prevProps.scanner.enableStartScan) {
        localSerieId.id.push(id);
        localSerieId.images.push(this.props.scanner.localSerie.images);
        prevProps.scannerAction.changeStore('activeSequence', localSerieId.images.length - 1);
        i++;
        prevProps.scannerAction.storeSequence(i, id);
        this.setState({
          localSerieId,
          brightness: 100,
          contrast: 100,
          opacity: 0,
        })

      }
    }
    if (prevProps.scanner.examEnded !== this.props.scanner.examEnded) {

      if (this.props.scanner.examEnded) {
        this.setState({
          point: {},
          startPoint: {},
          direction: null,
          renderedPlan: false,

          isBrigthnessLevel: false,
          defaultBrightness: 100,
          defaultContrast: 100,
          brightness: 100,
          contrast: 100,
          opacity: 0,
          lastSlide: false,
          currentSlide: 0,
          localSerieId: {
            id: [],
            images: [],
          },
          newActiveSequence: 0,
        });
      }
    }
  }

  changeBrightness = (e) => {
    if (this.state.isBrigthnessLevel && e.clientX !== undefined) {
      const xAxisMove = this.state.startPoint.startXPos - e.clientX;
      const yAxisMove = this.state.startPoint.startYPos - e.clientY;
      let brightness = this.state.defaultBrightness - yAxisMove * 2;
      let contrast = this.state.defaultBrightness - xAxisMove * 2;
      let opacity = 0 - yAxisMove / 2;
      this.setState({ brightness, contrast, opacity })
    } else if (e.clientX === undefined) {
      const xAxisMove = this.state.startPoint.startXPos - e.touches[0].clientX;
      const yAxisMove = this.state.startPoint.startYPos - e.touches[0].clientY;
      let brightness = this.state.defaultBrightness - yAxisMove * 2;
      let contrast = this.state.defaultBrightness - xAxisMove * 2;
      let opacity = 0 - yAxisMove / 2;
      this.setState({ brightness, contrast, opacity })
    }
    e.preventDefault();
  }

  onMouseMove = e => {
    let items = null
    if (e.clientX !== undefined) {
      items = e.nativeEvent.target.closest(".autoview-container").querySelectorAll('img')
    } else {
      items = e.touches[0].target.closest(".autoview-container").querySelectorAll('img')
    }
    this.changeBrightness(e, items)
  }

  onMouseDown = e => {
    this.setState({
      isBrigthnessLevel: true,
      startPoint: {
        startXPos: e.clientX || e.touches[0].clientX,
        startYPos: e.clientY || e.touches[0].clientY
      }
    })
  }

  onMouseUp = () => {
    this.setState({ isBrigthnessLevel: false })
    this.props.scannerAction.changeStore('brightness', this.state.brightness)
    this.props.scannerAction.changeStore('contrast', this.state.contrast)
    this.props.scannerAction.changeStore('opacity', this.state.opacity)
  }

  handleChange = (e, { name, value }) => {
    let imagesIndex = this.state.localSerieId.id.findIndex(el => el === value);
    this.props.scannerAction.changeStore('imageStep', 0);
    this.props.scannerAction.changeStore('activeSequence', imagesIndex);
  }

  onContextMenu = e => {
    e.preventDefault()
  }

  renderSeriesImages = () => {
    const activeSequence = this.state.newActiveSequence;
    const images = this.state.localSerieId.images[activeSequence];
    const sequence = this.props.scanner.sequenceState.map(el => el);
    sequence.shift()
    return (
      <CarouselProvider
        currentSlide={this.props.scanner.imageStep}
        dragEnabled={false}
        touchEnabled={false}
        naturalSlideWidth={50}
        naturalSlideHeight={50}
        totalSlides={images.length}
      >
        <Slider classNameAnimation='null' >
          {images.map((image, index) => (
            <Slide index={image.id} key={index}>
              <Image
                src={image}
                draggable={false}
                hasMasterSpinner

                style={{ filter: `brightness(${this.state.brightness}%) contrast(${this.state.contrast}%)` }}
              />
              <div
                className='slide-white-overlay'
                style={{
                  filter: `opacity(${this.state.opacity}%)`, opacity: `${this.state.opacity}%`
                }}
              />
            </Slide>
          ))}
        </Slider>
        <div className='autoview-button-block'>
          <ButtonBack
            grey='grey'
            disabled={(this.props.scanner.imageStep < images.length - 1) && !this.props.scanner.imageStopped ? true : null}
            onClick={e => { this.props.scannerAction.changeStore('imageStep', e._targetInst.return.stateNode.props.currentSlide - 1); }}>
            Prior
            </ButtonBack>
          <ButtonNext
            grey='grey'
            disabled={(this.props.scanner.imageStep < images.length - 1) && !this.props.scanner.imageStopped ? true : null}
            onClick={e => { this.props.scannerAction.changeStore('imageStep', e._targetInst.return.stateNode.props.currentSlide + 1); }}>
            Next
            </ButtonNext>
          <Dropdown
            disabled={(sequence.length > 0) ? false : true}
            compact
            selection
            options={sequence}
            value={sequence[activeSequence].value}
            onChange={this.handleChange}
          />
        </div>
      </CarouselProvider>
    );
  };

  renderEmptyImage = () => {
    return (
      <div>
        <div className="empty-image" />
        <div className='autoview-button-block empty'>
          <Button content="Prior" />
          <Button content="Next" />
          <Dropdown
            grey='grey'
            disabled
            compact
            selection
            options={[{ text: '1', value: 0 }]}
          />
        </div>
      </div>
    );
  };

  render() {
    const {
      scan: { isRunning, cancelled },
      files: { images }
    } = this.props.scanner;
    return (
      <div className="autoview-container"
        onMouseUp={this.onMouseUp}
        onTouchEnd={e => { this.onMouseUp(e) }}
        onMouseMove={this.onMouseMove}
        onTouchMove={e => { e.preventDefault(); this.onMouseMove(e) }}
        onMouseDown={this.onMouseDown}
        onTouchStart={e => { this.onMouseDown(e) }}
        onContextMenu={this.onContextMenu}
      >
        <Header as="h4" content="Autoview" textAlign="center" />
        {(images.length && !isRunning && !cancelled)
          ? this.renderSeriesImages()
          : this.renderEmptyImage()}
      </div>
    );
  }
}

const mapStateToProps = ({ scanner }) => ({ scanner });

const mapDispatchToProps = dispatch => ({
  scannerAction: bindActionCreators(
    {
      storeSequence,
      imageStep,
      changeStore,
      updateActiveSeries,
      cancelScan,
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoView);
