import React from 'react';
import Draggable from 'react-draggable';
import AutoViewModal from './AutoViewModal'
import { Icon } from "semantic-ui-react";

const defaultPositionX = 695
const defaultPositionY = -525

class AutoviewDraggable extends React.Component {

    handleDrag = (e, ui) => {
        console.log(ui)
    }


    render() {
        return (
            <Draggable
                defaultPosition={{ x: defaultPositionX, y: defaultPositionY }}
                handle=".graphic-rx-autoview-header"
                scale={1}
                onDrag={this.handleDrag}
            >
                <div
                    className="autoview-graphic-rx"
                    id="graphic-rx-autoview"
                    style={{ opacity: `${this.props.showAutoview}` }}
                >
                    <div className="graphic-rx-autoview-header">Autoview <Icon name="close" onClick={() => this.props.closeAutoview()} /></div>
                    <div className='content'>
                        <AutoViewModal localSerieId={this.props.localSerieId} />
                    </div>
                </div>
            </Draggable>
        )
    }
}

export default AutoviewDraggable;
