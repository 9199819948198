import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changeTooltipsStore } from '../../actions';
import './Guide.css'

class Guide extends React.Component {
    state = {
        patientInfo: 'block',
        patientInfoFull: 'none',
        saveSeries: 'none',
        prepareScan: 'none',
        scanButton: 'none'
    }

    componentDidUpdate (prevProps) {
        if (prevProps.scanner.patientInfoGuide !== this.props.scanner.patientInfoGuide)
            this.setState({patientInfo: this.props.scanner.patientInfoGuide})
        if (prevProps.scanner.patientInfoFullGuide !== this.props.scanner.patientInfoFullGuide)
            this.setState({patientInfoFull: this.props.scanner.patientInfoFullGuide})
        if (prevProps.scanner.saveSeriesGuide !== this.props.scanner.saveSeriesGuide)
            this.setState({saveSeries: this.props.scanner.saveSeriesGuide})
        if (prevProps.scanner.prepareScanGuide !== this.props.scanner.prepareScanGuide)
            this.setState({prepareScan: this.props.scanner.prepareScanGuide})
        if (prevProps.scanner.scanButtonGuide !== this.props.scanner.scanButtonGuide)
            this.setState({scanButton: this.props.scanner.scanButtonGuide})
    }

    render () {
        return (
            <div className="guide-container">
                <div style={{display: `${this.state.patientInfo}`}} className="tooltip-guide-box tooltip-guide-patient-info">Enter patient information</div>
                <div style={{display: `${this.state.patientInfoFull}`}} className="tooltip-guide-box tooltip-guide-patient-info-full">Select a coil</div>
                <div style={{display: `${this.state.saveSeries}`}} className="tooltip-guide-box tooltip-guide-save-series">Change parameters and click the Save Series button</div>
                <div style={{display: `${this.state.prepareScan}`}} className="tooltip-guide-box tooltip-guide-prepare-scan">Next click the Prepare to Scan button</div>
                <div style={{display: `${this.state.scanButton}`}} className="tooltip-guide-box tooltip-guide-scan-button">Next click the Scan button</div>
            </div>
        )
    }
}

const mapStateToProps = ({ tooltips, scanner}) => ({ tooltips, scanner });
const mapDispatchToProps = dispatch => ({
  tooltipAction: bindActionCreators({ changeTooltipsStore }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Guide);