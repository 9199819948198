import React from 'react'
import { Modal, Dropdown } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { storeSequence, imageStep, changeStore, cancelScan } from '../../actions';
import {
    CarouselProvider,
    Slide,
    ButtonBack,
    ButtonNext,
    Image,
    Slider
} from 'pure-react-carousel';
import './ScaledImage.css'
import zoomIcon from '../../images/zoom_icon.png'


class ScaledImage extends React.Component {

    state = {
        modalShow: false,
        localSerieId: {
            id: [],
            images: [],
        },
        isBrigthnessLevel: false,
        defaultBrightness: 100,
        defaultContrast: 100,
        brightness: 100,
        contrast: 100,
        opacity: 0,
        startPoint: {},
        currentSlide: 0,
        newActiveSequence: 0,
    }

    componentDidUpdate(prevProps) {
        // dropdown functionality
        if (prevProps.scanner.newLocalSerieId !== this.props.scanner.newLocalSerieId) {
            this.setState({ localSerieId: this.props.scanner.newLocalSerieId })
        }
        if (prevProps.scanner.activeSequence !== this.props.scanner.activeSequence) {
            this.setState({ newActiveSequence: this.props.scanner.activeSequence })
        }
        if (prevProps.scanner.imageStep !== this.props.scanner.imageStep) {
            this.setState({ currentSlide: this.props.scanner.imageStep })
        }


        let array = this.state.localSerieId;

        let i = prevProps.scanner.sequenceNumber;
        let id = this.props.scanner.activeSerie.id;

        if (i !== undefined && id !== undefined) {
            const r = array.id.findIndex(el => el === id);
            if (r === -1 && prevProps.scanner.enableStartScan) {
                array.id.push(id);
                array.images.push(this.props.scanner.localSerie.images);
                prevProps.scannerAction.changeStore('activeSequence', array.images.length - 1);
                this.setState({ localSerieId: array })
            } else if (prevProps.scanner.activeSequence !== this.props.scanner.activeSequence) {
                // this.props.scannerAction.changeStore('activeSequence', r);
            }
        }
        if (prevProps.scanner.examEnded !== this.props.scanner.examEnded) {
            if (this.props.scanner.examEnded) {
                this.setState({
                    localSerieId: {
                        id: [],
                        images: [],
                    },
                });
            }
        }

    }

    changeBrightness = (e, items) => {
        if (this.state.isBrigthnessLevel) {
            const xAxisMove = this.state.startPoint.startXPos - e.clientX;
            const yAxisMove = this.state.startPoint.startYPos - e.clientY;
            let brightness = this.state.defaultBrightness - yAxisMove;
            let contrast = this.state.defaultBrightness - xAxisMove;
            let opacity = 0 - yAxisMove / 2;
            this.setState({ brightness, contrast, opacity })
        }
        e.preventDefault();
    }

    onMouseMove = e => {
        const items = e.nativeEvent.target.closest(".scaled-autoview-modal-container").querySelectorAll('img');
        this.changeBrightness(e, items)
    }

    onMouseDown = e => {
        this.setState({
            isBrigthnessLevel: true,
            startPoint: {
                startXPos: e.clientX,
                startYPos: e.clientY
            }
        })
    }

    onMouseUp = () => {
        this.setState({
            isBrigthnessLevel: false,
        })

    }

    modalShow = () => {
        this.setState({
            modalShow: true,
            currentSlide: this.props.scanner.imageStep,
            brightness: this.props.scanner.brightness,
            contrast: this.props.scanner.contrast,
            opacity: this.props.scanner.opacity,
        })
    }

    modalClose = () => {
        this.setState({ modalShow: false })
        this.props.scannerAction.changeStore('imageStep', this.state.currentSlide);
        this.props.scannerAction.changeStore('brightness', this.state.brightness)
        this.props.scannerAction.changeStore('contrast', this.state.contrast)
        this.props.scannerAction.changeStore('opacity', this.state.opacity)
    }

    handleChange = (e, { name, value }) => {
        let imagesIndex = this.state.localSerieId.id.findIndex(el => el === value);
        this.setState({ currentSlide: 0 });
        this.props.scannerAction.changeStore('activeSequence', imagesIndex);
    }

    renderSeriesImage = () => {
        const activeSequence = this.state.newActiveSequence;
        const images = this.state.localSerieId.images[activeSequence];
        const sequence = this.props.scanner.sequenceState.map(el => el);
        sequence.shift()

        return (
            <CarouselProvider
                currentSlide={this.state.currentSlide}
                dragEnabled={false}
                touchEnabled={false}
                naturalSlideWidth={50}
                naturalSlideHeight={50}
                totalSlides={images.length}
            >
                <div className='slider-container'>
                    <Slider classNameAnimation='null' >
                        {images.map((image, index) => (
                            <Slide index={image.id} key={index}>
                                <Image
                                    src={image}
                                    draggable={false}
                                    onError={() => { console.log('Error: Image fails to load'); this.props.scannerAction.cancelScan() }}
                                    style={{ filter: `brightness(${this.state.brightness}%) contrast(${this.state.contrast}%)` }}
                                />
                                <div
                                    className='zoomed-slide-white-overlay'
                                    style={{ filter: `opacity(${this.state.opacity}%)` }}
                                />
                            </Slide>
                        ))}
                    </Slider>
                </div>
                <div className='scaled-autoview autoview-button-block'>
                    <ButtonBack
                        id='prior-button'
                        grey='grey'
                        disabled={(this.props.scanner.imageStep < images.length - 1) && !this.props.scanner.imageStopped ? true : null}
                        onClick={() => { this.setState({ currentSlide: this.state.currentSlide - 1 }) }}>
                        Prior
                    </ButtonBack>
                    <ButtonNext
                        id='next-button'
                        grey='grey'
                        disabled={(this.props.scanner.imageStep < images.length - 1) && !this.props.scanner.imageStopped ? true : null}
                        onClick={() => { this.setState({ currentSlide: this.state.currentSlide + 1 }) }}>
                        Next
                    </ButtonNext>
                    <Dropdown
                        disabled={(sequence.length > 0) ? false : true}
                        compact
                        selection
                        options={sequence}
                        defaultValue={sequence[activeSequence].value}
                        onChange={this.handleChange}
                    />
                </div>
            </CarouselProvider>
        );
    };


    render() {
        const {
            scan: { isRunning, cancelled },
            files: { images }
        } = this.props.scanner;
        return (
            <div className="autoview-zoom">
                <span className='autoview-zoom-button'>
                    <button
                        onClick={() => this.modalShow()}
                        disabled={images.length === 0 || ((this.props.scanner.imageStep < images.length - 1) && !this.props.scanner.imageStopped) ? true : null}>
                        <img src={zoomIcon} alt='zoom img' />
                    </button>
                </span>

                <Modal
                    className='scaled-autoview-modal'
                    open={this.state.modalShow}
                    onClose={() => this.modalClose()}
                    closeOnDimmerClick={false}
                    closeIcon={true}
                    size='mini'>
                    <Modal.Header>
                        AutoView
                    </Modal.Header>

                    <Modal.Content>
                        <div
                            className='scaled-autoview-modal-container'
                            onMouseUp={this.onMouseUp}
                            onMouseMove={this.onMouseMove}
                            onMouseDown={this.onMouseDown}
                        >
                            {(images.length && !isRunning && !cancelled)
                                ? this.renderSeriesImage()
                                : null}
                        </div>
                    </Modal.Content>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = ({ scanner }) => ({ scanner });

const mapDispatchToProps = dispatch => ({
    scannerAction: bindActionCreators(
        {
            storeSequence,
            imageStep,
            changeStore,
            cancelScan
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(ScaledImage);



