import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Form, Header, Button } from "semantic-ui-react";
import { changeLocalSerieField } from "../../actions";
import Tooltip from '../tooltip/Tooltip';

import "./styles.css";
import { defaultState } from '../../utils/acquisitionTimingDefaultState';

class AcquisitionTiming extends Component {
  state = {
    scanTimings: []
  };

  handleChange(value, name) {
    this.props.scannerAction.changeLocalSerieField({
      [name]: value
    });
  }

  componentDidMount() {
    this.setState({scanTimings: defaultState})
  }

  componentWillReceiveProps(nextProps) {
    const { scanTimings } = this.state;
    const {examEnded} = nextProps.scanner
    for (let i = 0; i < scanTimings.length; i++) {
      const element = scanTimings[i];
      if (nextProps.localSerie[element.name] !== null && nextProps.localSerie[element.name] !== undefined) {
        element.value = nextProps.localSerie[element.name];
      } else element.value = 0;
    }

    if (examEnded) {
      this.setState({scanTimings: defaultState})
    }

  }

  render() {

    return (
      <div className="acquisition-timing">
        <Header
          as="h4"
          content="Acquisition Timing"
          textAlign="center"
        />
        <div className="acquisition-timing-table">
          {this.state.scanTimings.map((item, index) => {
            return (
              <div key={index} className="acquisition-timing-table-row">
                <div className='acquisition-timing-table-coll-input-label'>{item.label}</div>
                <div className="acquisition-timing-table-coll-input">
                  <Form.Input
                    name={item.name}
                    disabled={item.disabled}
                    onChange={e => this.handleChange(e.target.value, item.name)}
                    value={item.value}
                    type="text"
                    min={item.min}
                    max={item.max}
                  />
                </div>
                <div className="acquisition-timing-table-coll-button">
                  {
                    item.after.type === 'button' ?
                      item.after.popup ? <Tooltip paramKey={item.name} key={index} trigger={<Button disabled={item.disabled} color="grey" />} />
                      : <Button disabled={item.disabled} color="grey" />
                      :
                      <div className="button">{item.after.value}</div>
                  }
                </div>
              </div>
            )
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ scanner }) => ({ localSerie: scanner.localSerie, scanner });
const mapDispatchToProps = dispatch => ({
  scannerAction: bindActionCreators({ changeLocalSerieField }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AcquisitionTiming);
