import React, { Component } from 'react';
import { Modal, Popup } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Api } from '../../services/api';
import Sound from 'react-sound';
import {
  endExam,
  startScan,
  stopScan,
  storeSeries,
  storeSeriesFiles,
  syncSeries,
  prepareScan,
  changeEnabledStartScan,
  cancelScan,
  imageStep,
  changeStore,
  canvasUpdateGraphicrx,
} from '../../actions';
import './style.css';

let audio1 = new Audio()
let audio2 = new Audio()

class ScannerParameters extends Component {
  state = {
    selectedParameter: {},
    protocols: [],
    endTimer: 0,
    leftScanTime: 0,
    modalOpen: false,
    paused: false,
    playStatus: Sound.status.PLAYING,
    wasStopped: false,
    timer: '',
    audioSrc: '',
    audio: [],
    noAudio: false,
    audio1Position: 1,
    pausedAudio: '',
    additionalTimer: '',
    scanTimeClass: null,
    firstSound: new Audio(),
    secondSound: new Audio(),
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.scanner.activeSerie !== this.props.scanner.activeSerie) {
      const response = await Api.series.getFiles(this.props.scanner.activeSerie.id)
      let sounds = [];
      if (response.data && response.data.data) sounds = response.data.data.sounds
      if (sounds[0] !== undefined) {
        if (sounds.length > 1) {
          sounds.sort((a, b) => {
            return a.position - b.position;
          });
          audio1.src = sounds[0].path
          audio1.load()
          audio2.src = sounds[1].path
          audio2.load()
          audio2.addEventListener('ended', () => {
            if (this.props.scanner.scan.isRunning) audio2.play()
          })
          this.setState({ audio: sounds, audioSrc: sounds[0].path, firstSound: audio1, secondSound: audio2 })
        } else {
          if (sounds[0].position === 2) this.setState({ audio1Position: 2 })
          audio1.src = sounds[0].path
          audio1.load()
          this.setState({
            audioSrc: sounds[0].path,
            audio: sounds,
            firstSound: audio1, secondSound: audio2
          });
        }
      } else this.setState({
        audioSrc: '',
        noAudio: true
      });
    }

    if (prevProps.scanner.scan.canceled !== this.props.scanner.scan.canceled) {
      if (this.props.scanner.scan.canceled) this.modalStop()
    }

    if (prevProps.scanner.examEnded !== this.props.scanner.examEnded) {
      if (this.props.scanner.examEnded) {
        this.setState({ scanTimeClass: null, leftScanTime: 0, })
      }
    }

    if (JSON.stringify(this.props.scanner.localSerie) !== JSON.stringify(prevProps.scanner.localSerie)) {
      if (this.props.scanner.localSerie) {
        this.setState({
          endTimer: this.getEndTimer(this.props.scanner.localSerie),
        });
      }
    }
  }

  onScanStart = async () => {
    const { localSerie } = this.props.scanner;

    if (localSerie.id) {
      const response = await Api.series.getFiles(localSerie.id)

      this.props.scannerAction.changeEnabledStartScan(false);

      if (response.data && response.data.data) {
        this.props.scannerAction.changeStore('files', {
          images: [],
          sounds: []
        });
        this.props.scannerAction.storeSeriesFiles(response.data.data);
      }

      let isSafari = navigator.vendor.indexOf('Apple') !== -1;

      if (this.props.scanner.files.sounds[0] === undefined || isSafari) {
        this.startEndTimer();
        this.props.scannerAction.startScan();
      } else if (this.state.audio1Position === 1) {
        this.props.scannerAction.startScan();
        this.state.firstSound.addEventListener('ended', this.startEndTimer)
        this.state.firstSound.play()
      } else {
        this.props.scannerAction.startScan();
        this.startEndTimer()
      }
    } else {
      toastr.confirm('Please select protocol first!', {
        disableCancel: true
      });
    }
    this.props.scannerAction.changeStore('scanButtonGuide', 'none')
    this.saveLogs();
  };

  saveLogs = async () => {
    await Api.log.addProtocol(this.props.user.activeLogId, this.props.scanner.protocol.id);
    await Api.log.addSequence(this.props.user.activeLogId, this.props.scanner.localSerie.id);
  }

  startEndTimer = () => {
    if (this.state.audio1Position === 1) this.state.secondSound.play()
    else this.state.firstSound.play()
    const timer = setInterval(() => {
      if (!this.state.paused) {
        this.setState({
          leftScanTime: this.state.leftScanTime - 1,
        }, () => {
          if (this.state.leftScanTime < 0) {
            this.onScanStop();
          }
        })
      }
    }, 1000);
    this.setState({ timer: timer })
  }

  getEndTimer = (activeSerie) => {
    let result = ((activeSerie.tr * activeSerie.phaseMatrix * activeSerie.nex) / activeSerie.reductionFactor) / 1000;
    if (isNaN(result)) result = 0;
    if (result < 1 && result > 0) result = 1;
    return result;
  }

  show = () => {
    [this.state.firstSound, this.state.secondSound].forEach(audio => {
      if (!audio.paused) {
        this.setState({ pausedAudio: audio.currentSrc })
        audio.pause()
      }
    })
    this.setState({ paused: true, modalOpen: true, playStatus: Sound.status.PAUSED });
  }

  close = () => {
    [this.state.firstSound, this.state.secondSound].forEach(audio => {
      if (audio.currentSrc === this.state.pausedAudio) audio.play()
    })
    this.setState({ modalOpen: false, paused: false, playStatus: Sound.status.PLAYING })
  }

  onScanStop = () => {
    clearInterval(this.state.timer);
    if (this.state.audio1Position === 1) this.state.secondSound.pause()
    else this.state.firstSound.pause()
    let i = this.props.scanner.imageStep;
    const timer = setInterval(() => {
      this.props.scannerAction.changeStore('imageStep', i++)
      if (i === this.props.scanner.files.images.length || this.props.scanner.changedSerie || this.props.scanner.modalIsOpen) {
        clearInterval(this.state.additionalTimer);
        this.props.scannerAction.imageStep(this.props.scanner.files.images.length - 1);
      }
    }, 1000);
    this.setState({ additionalTimer: timer, leftScanTime: 0 })

    this.props.scannerAction.stopScan();
  }

  modalStop = () => {
    clearInterval(this.state.timer);
    this.props.scannerAction.cancelScan();
    this.state.firstSound.pause()
    this.state.secondSound.pause()
    this.setState({ leftScanTime: 0 })
    this.setState({ modalOpen: false, paused: false, playStatus: Sound.status.PLAYING })
  }

  onPrapereToScan = async () => {
    this.props.scannerAction.syncSeries();
    this.setState({
      endTimer: this.getEndTimer(this.props.scanner.localSerie),
    }, () => {
      if (this.state.endTimer !== 0) {
        this.props.scannerAction.prepareScan();
        this.setState({ scanTimeClass: null, leftScanTime: this.state.endTimer });
      } else
        this.setState({ scanTimeClass: 'zero-scan-time', leftScanTime: 0 })
    });

    this.props.scannerAction.changeStore('saveSeriesGuide', 'none')
    this.props.scannerAction.changeStore('prepareScanGuide', 'block')

  }

  handleAudioError = () => {
    console.log('audio error')
    this.modalStop()
  }

  render() {
    const {
      scan: {
        isRunning,
      },
      enableSaveSeries,
      enableStartScan,
      activeSerie
    } = this.props.scanner;

    const { leftScanTime } = this.state;
    const open = this.state.modalOpen;
    const style = {
      opacity: (this.state.scanTimeClass === 'zero-scan-time') ? 1 : 0,
    }

    return (
      <div id="scan-params">
        <div className="scan-params-row">
          <button
            onClick={this.onPrapereToScan}
            disabled={!enableSaveSeries}
            className='save-series-button'>Save Series</button>
          <div className="scan-params-row-item">
            <div>
              <span className={(isRunning) ? 'rx-scan-time-bordered' : 'rx-scan-time'}>
                RX Scan Time:&nbsp;
                <Popup
                  className='incorrect-time-popup'
                  content="Scan time isn't correct"
                  trigger={
                    <span className={this.state.scanTimeClass}>
                      {isNaN(leftScanTime) ? 'NaN' : Number(leftScanTime * 0.01).toFixed(2)}
                    </span>
                  }
                  on='hover'
                  position='right center'
                  size='mini'
                  style={style}
                />
              </span>
            </div>
            <div># of Acqs.: {isNaN(activeSerie.nex) ? '' : Number(activeSerie.nex).toFixed(2)}</div>
          </div>
          <div className="scan-params-row-item">
            <div>Max # of slices: {activeSerie.numberSlices}</div>
            <div>Total # of slices: {activeSerie.numberSlices}</div>
          </div>
          <div className="scan-params-row-item">
            <div>Rel. SNR(%): 80</div>
            <div>IDrive FPS: 20</div>
          </div>
          <div className="scan-params-row-item">
            <div>Est SAR: 0.9</div>
            <div>Peak SAR: 1.8</div>
          </div>
          <div className="scan-params-row-item">
            <div>dB/dt: First level</div>
            <div>SAR: First level</div>
          </div>
          <div className="scan-params-row-buttons">
            {!isRunning && <button
              disabled={!enableStartScan}
              onClick={this.onScanStart}
              className='scan-pause-button'
            >Scan</button>}

            {isRunning && <button
              className='scan-pause-button'
              onClick={() => this.show()}
            >Pause</button>}
            <Modal size={'mini'} open={open} onClose={this.close} className='modal-scan-pause'>
              <Modal.Header className='modal-scan-header'>Select an option</Modal.Header>
              <Modal.Actions className='modal-scan-buttons'>
                <button onClick={this.close}>Resume Scan</button>
                <button onClick={this.modalStop}>Cancel Scan</button>
              </Modal.Actions>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ scanner, user }) => ({ scanner, user });
const mapDispatchToProps = dispatch => ({
  scannerAction: bindActionCreators(
    {
      storeSeries,
      storeSeriesFiles,
      changeEnabledStartScan,
      startScan,
      endExam,
      stopScan,
      syncSeries,
      prepareScan,
      cancelScan,
      imageStep,
      changeStore
    },
    dispatch
  ),
  planSlicesAction: bindActionCreators({ canvasUpdateGraphicrx }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScannerParameters);
