import { createActions } from 'redux-actions';

const END_EXAM = 'END_EXAM';
const STOP_SCAN = 'STOP_SCAN';
const START_SCAN = 'START_SCAN';
const CANCEL_SCAN = 'CANCEL_SCAN';
const SELECT_COIL = 'SELECT_COIL';
const STORE_SERIES = 'STORE_SERIES';
const SELECT_ACTIVE_SERIE = 'SELECT_ACTIVE_SERIE';
const STORE_SERIES_FILES = 'STORE_SERIES_FILES';
const STORE_MIP_SERIES_FILES = 'STORE_MIP_SERIES_FILES';
const UPDATE_ACTIVE_SERIES = 'UPDATE_ACTIVE_SERIES';
const TOGGLE_PROTOCOL_PLACE = 'TOGGLE_PROTOCOL_PLACE';
const SELECT_PREVIOUS_SERIES = 'SELECT_PREVIOUS_SERIES';
const UPDATE_PRESSURE_INJECTOR = 'UPDATE_PRESSURE_INJECTOR';
const STOP_CAROUSEL_AUTOPLAY = 'STOP_CAROUSEL_AUTOPLAY';
const START_CAROUSEL_AUTOPLAY = 'START_CAROUSEL_AUTOPLAY';
const CHANGE_LOCAL_SERIE_FIELD = 'CHANGE_LOCAL_SERIE_FIELD';
const SYNC_SERIES = 'SYNC_SERIES';
const PREPARE_SCAN = 'PREPARE_SCAN';
const CHANGE_ENABLED_START_SCAN = 'CHANGE_ENABLED_START_SCAN';
const EXAM_STATUS = 'EXAM_STATUS';
const NEW_EXAM = 'NEW_EXAM';
const IMAGE_STEP = 'IMAGE_STEP';
const STORE_SEQUENCE = 'STORE_SEQUENCE';
const CHANGE_STORE = 'CHANGE_STORE';
const NEW_LOCAL_SERIE_ID = 'NEW_LOCAL_SERIE_ID';

const {
  endExam,
  stopScan,
  startScan,
  cancelScan,
  selectCoil,
  storeSeries,
  selectActiveSerie,
  storeSeriesFiles,
  storeMipSeriesFiles,
  updateActiveSeries,
  toggleProtocolPlace,
  selectPreviousSeries,
  updatePressureInjector,
  changeLocalSerieField,
  syncSeries,
  prepareScan,
  stopCarouselAutoplay,
  startCarouselAutoplay,
  changeEnabledStartScan,
  examStatus,
  newExam,
  imageStep,
  storeSequence,
  changeStore,
  newLocalSerieId,
} = createActions({
  [END_EXAM]: () => ({}),
  [STOP_SCAN]: () => ({}),
  [START_SCAN]: () => ({}),
  [CANCEL_SCAN]: () => ({}),
  [SELECT_COIL]: selectedCoil => ({ selectedCoil }),
  [STORE_SERIES]: (protocol, series, mipSeries, isProtocolOpen) => ({ protocol, series, mipSeries, isProtocolOpen }),
  [SELECT_ACTIVE_SERIE]: data => ({ data }),
  [STORE_SERIES_FILES]: serie => ({ serie }),
  [STORE_MIP_SERIES_FILES]: serie => ({ serie }),
  [UPDATE_ACTIVE_SERIES]: (field, value) => ({ field, value }),
  [TOGGLE_PROTOCOL_PLACE]: isOpened => ({ isOpened }),
  [SELECT_PREVIOUS_SERIES]: () => ({}),
  [UPDATE_PRESSURE_INJECTOR]: (field, value) => ({ field, value }),
  [CHANGE_LOCAL_SERIE_FIELD]: fields => ({ fields }),
  [SYNC_SERIES]: () => ({}),
  [PREPARE_SCAN]: () => ({}),
  [STOP_CAROUSEL_AUTOPLAY]: () => ({}),
  [START_CAROUSEL_AUTOPLAY]: () => ({}),
  [CHANGE_ENABLED_START_SCAN]: (enabled) => (enabled),
  [EXAM_STATUS]: (status, isCoil) => ({status, isCoil}),
  [NEW_EXAM]: () => ({}),
  [IMAGE_STEP]: (i) => ({i}),
  [STORE_SEQUENCE]: (i, id) => ({i, id}),
  [CHANGE_STORE]: (name, value) => ({name, value}),
  [NEW_LOCAL_SERIE_ID]: data => ({ data }),
});

export {
  endExam,
  END_EXAM,
  stopScan,
  STOP_SCAN,
  startScan,
  cancelScan,  
  CANCEL_SCAN,
  selectCoil,
  SELECT_COIL,
  START_SCAN,
  storeSeries,
  STORE_SERIES,
  selectActiveSerie,
  SELECT_ACTIVE_SERIE,
  storeSeriesFiles,
  STORE_SERIES_FILES,
  storeMipSeriesFiles,
  STORE_MIP_SERIES_FILES,
  updateActiveSeries,
  toggleProtocolPlace,
  TOGGLE_PROTOCOL_PLACE,
  UPDATE_ACTIVE_SERIES,
  selectPreviousSeries,
  SELECT_PREVIOUS_SERIES,  
  updatePressureInjector,
  UPDATE_PRESSURE_INJECTOR,
  changeLocalSerieField,
  CHANGE_LOCAL_SERIE_FIELD,
  stopCarouselAutoplay,
  STOP_CAROUSEL_AUTOPLAY,
  startCarouselAutoplay,
  START_CAROUSEL_AUTOPLAY,
  syncSeries,
  SYNC_SERIES,
  prepareScan,
  PREPARE_SCAN,
  changeEnabledStartScan,
  CHANGE_ENABLED_START_SCAN,
  examStatus,
  EXAM_STATUS,
  newExam,
  NEW_EXAM,
  imageStep,
  IMAGE_STEP,
  storeSequence,
  STORE_SEQUENCE,
  changeStore,
  CHANGE_STORE,
  newLocalSerieId,
  NEW_LOCAL_SERIE_ID
};
