export const defaultState = [{
        name: 'frequencyMatrix',
        disabled: false,
        label: 'Freq',
        after: {
        type: 'button',
        popup: 'Freq (Frequency Matrix) controls the number and range of frequencies that are collected during signal sampling and spatial resolution in the frequency direction.'
        },
        value: ''
    }, {
        name: 'phaseMatrix',
        disabled: false,
        label: 'Phase',
        after: {
        type: 'button',
        popup: 'Phase (Phase Matrix) controls the number of phase encoding steps and spatial resolution in the phase direction. Phase Matrix affects spatial resolution, SNR and scan time. As the phase matrix increases, spatial resolution and scan time increase, however, SNR decreases.'
        },
        value: ''
    }, {
        name: 'nex',
        disabled: false,
        label: 'NEX',
        after: {
        type: 'button',
        popup: 'Nex (Number of Excitations) controls the number of the times each phase encoding step is performed. Nex affects SNR and scan time. As NEX increases, SNR and Scan time increases.'
        },
        value: ''
    }, {
        name: 'phaseDirection',
        disabled: false,
        label: 'Phase DIR',
        after: {
        type: 'button'
        },
        value: ''
    }, {
        name: 'frequencyDirection',
        disabled: false,
        label: 'Freq DIR',
        after: {
        type: 'button'
        },
        value: ''
    }, {
        name: 'shim',
        disabled: false,
        label: 'Shim',
        after: {
        type: 'button'
        },
        value: 'Auto'
    }, {
        name: 'contrastAmount',
        disabled: false,
        label: 'Amt',
        after: {
        type: 'label',
        value: 'ml'
        },
        value: 0
    }, {
        name: 'contrastAgent',
        disabled: false,
        label: 'Agent',
        after: {
        type: 'label',
        value: ''
        },
        value: 0
    }]