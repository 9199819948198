import BaseApiService from './base';

export class ProtocolApiService extends BaseApiService {
  // eslint-disable-next-line
  constructor() {
    super();
  }

  getAll = () =>  this.request({
    method: 'get',
    url: '/api-v2/protocol'
  });

  getByBodyPart = partOfBody => this.request({
    method: 'post',
    url: '/api-v2/protocol/partOfBody',
    data: { partOfBody }
    });

  getSeries = protcolId => this.request({
    method: 'get',
    url: `/api-v2/sequence/protocol/${protcolId}`,
    // pagination
    params: {
      limit: -1, // all items
      offset: 0
    }
  });

  getMipSeries = protcolId => this.request({
    method: 'get',
    url: `/api-v2/sequence/protocol/${protcolId}/reconstruction`
  });
}
