import React, {
    Component
} from 'react';
import {
    fabric
} from 'fabric';
import {
    connect
} from 'react-redux';
import {
    bindActionCreators
} from 'redux';
import {
    canvasUpdateGraphicrx,
    enablePlanslice,
    disablePlanslice,
    changeStore,
    setActiveViewport,
    changePlanslicesStore,
    setCoords
} from '../../actions';
import './style.css'

import horizontalImg from '../../images/horizontal-pattern.png'
import verticalImg from '../../images/vertical-pattern.png'

const VERTICAL = 'vertical';
const HORIZONTAL = 'horizontal';

class PlanSlicesImage extends Component {

    state = {
        point: {},
        startPoint: {},
        sat: true,
        direction: null,
        renderedPlan: false,
        isMouseDown: false,
        isMouseLeaved: false,
        isRightMouseDown: false,
        wasOpened: false,

        horizontalPattern: new fabric.Pattern({
            source: horizontalImg,
            repeat: 'repeat'
        }),
        verticalPattern: new fabric.Pattern({
            source: verticalImg,
            repeat: 'repeat'
        }),

        isBrigthnessLevel: false,
        defaultBrightness: 100,
        defaultContrast: 100,
        opacity: 0,
        objCoords: {},
        renderPlan: false,
    }

    prepareCanvas(width, height) {
        // Canvas loader
        const canvas = new fabric.Canvas(this.props.canvasID, {
            selection: false,
        });

        fabric.Object.prototype.transparentCorners = false;
        canvas.setHeight(height);
        canvas.setWidth(width);
        canvas.add(this.newRectangle())

        this.saveCanvas(canvas);
        return canvas;
    }

    removeObjects(canvas, name) {
        let objects = {};
        if (name)
            objects = canvas.getObjects(name);
        else
            objects = canvas.getObjects();

        for (let i in objects)
            canvas.remove(objects[i]);
    }

    saveCanvas(canvas) {
        this.props.planSlicesAction.canvasUpdateGraphicrx(this.props.canvasID, canvas);
    }

    // Draw rect by changing widht/height
    drawBaseRect(x, y, w, h) {
        const {
            direction,
            startPoint,
            point
        } = this.state;
        let {
            canvas
        } = this.props.planSlices;

        let options = {};

        const satOptions = ((this.props.options && this.props.options.type === HORIZONTAL) ||
            (!this.props.options && direction === HORIZONTAL)) ? {
                'width': x - startPoint.startXPos,
                'height': startPoint.height,
                'top': 10,
                'left': point.x,
                fill: 'rgba(236, 255, 255, 0.1)',
                opacity: 0.8,
                stroke: '#ecf76c',
                strokeWidth: 3,
                'hasControls': true,
                'cornerColor': 'cyan',
                'cornerSize': 8,
                'transparentCorners': false,
                'hasRotatingPoint': true,
                'hasBorders': true,
                'borderColor': '#ecf76c',
                rotatingPointOffset: 20,
            } : {
                'height': y - startPoint.startYPos,
                'width': startPoint.width,
                'top': point.y,
                'left': 10,
                fill: 'rgba(236, 255, 255, 0.1)',
                opacity: 0.8,
                stroke: '#ecf76c',
                strokeWidth: 3,
                'hasControls': true,
                'cornerColor': 'cyan',
                'cornerSize': 8,
                'transparentCorners': false,
                'hasRotatingPoint': true,
                'hasBorders': true,
                'borderColor': '#ecf76c',
                rotatingPointOffset: 20,
            };

        const rxHorOptions = {
            'width': x - startPoint.startXPos,
            'height': startPoint.height,
            'top': 10,
            'left': point.x,
            'fill': this.state.verticalPattern,
            'hasControls': true,
            'cornerColor': 'cyan',
            'cornerSize': 8,
            'transparentCorners': false,
            'hasRotatingPoint': true,
            'hasBorders': true,
            'borderColor': '#ecf76c',
            rotatingPointOffset: 20,
        }

        const rxVerOptions = {
            'height': y - startPoint.startYPos,
            'width': startPoint.width,
            'top': point.y,
            'left': 10,
            'fill': this.state.horizontalPattern,
            'hasControls': true,
            'cornerColor': 'cyan',
            'cornerSize': 8,
            'transparentCorners': false,
            'hasRotatingPoint': true,
            'hasBorders': true,
            'borderColor': '#ecf76c',
            rotatingPointOffset: 20,
        };

        canvas = canvas[this.props.canvasID];

        if (canvas === null)
            canvas = this.prepareCanvas(w, h);

        if (!startPoint.startXPos) return;

        let xAxis = Math.abs(startPoint.startXPos - x);
        let yAxis = Math.abs(startPoint.startYPos - y);
        if (Math.abs(xAxis - yAxis) < 2) return;

        let directionVector = (yAxis < xAxis) ?
            HORIZONTAL :
            VERTICAL;

        if (!direction)
            this.setState({
                direction: directionVector
            })

        if (this.props.planSlices.sat) {
            options = satOptions
        } else {
            options = ((this.props.options && this.props.options.type === HORIZONTAL) ||
                (!this.props.options && direction === HORIZONTAL)) ? rxHorOptions : rxVerOptions
        }

        const obj = canvas.getObjects()
        const item = canvas.item(obj.length - 1);
        item.set(options);
        item.setCoords();
        this.props.scannerAction.changeStore('objCoords', item.calcCoords())
        canvas.renderAll();
        this.saveCanvas(canvas);
    }

    componentDidMount() {
        let {
            canvas
        } = this.props.planSlices;
        canvas = canvas[this.props.canvasID];
        if (canvas !== null) {
            let oldCanvas = this.prepareCanvas(canvas.width, canvas.height);
            let array = canvas.getObjects();
            array.forEach((value) => {
                oldCanvas.add(value);
            });
            canvas = oldCanvas
            this.saveCanvas(oldCanvas)
            this.setState({ wasOpened: true })
        }
    }

    changeObjPosition() {
        let {
            canvas
        } = this.props.planSlices;

        canvas = canvas[this.props.canvasID];

        let item = canvas.getActiveObject()
        item.on('mouseover', e => {
            const {
                planeSliceEnable
            } = this.props.planSlices;
            e.target.lockMovementX = e.target.lockMovementY = !planeSliceEnable[this.props.canvasID];
        });

        this.saveCanvas(canvas);
    }

    changeBrightness = (e, items) => {
        if (this.state.isBrigthnessLevel && !this.props.planSlices.planeSliceEnable[this.props.canvasID]) {
            const xAxisMove = this.state.startPoint.startXPos - e.clientX;
            const yAxisMove = this.state.startPoint.startYPos - e.clientY;
            if (items) {
                items.forEach(item => {
                    return item.style.filter = `brightness(${this.state.defaultBrightness - yAxisMove}%) contrast(${this.state.defaultContrast - xAxisMove}%)`;
                })
            }
            let opacity = 0 - yAxisMove / 2;
            const whiteDivs = e.nativeEvent.target.closest(".plan-slices-modal-content-block").querySelectorAll('.rx-slide-white-overlay');
            this.setState({ opacity }, () => {
                whiteDivs.forEach(item => {
                    item.style.opacity = `${this.state.opacity / 100}`;
                })
            })
        }
    }

    onMouseMove = e => {
        let {
            canvas,
            planeSliceEnable
        } = this.props.planSlices;
        let canvasEx = canvas[this.props.canvasID];

        if ((planeSliceEnable[this.props.canvasID] && this.state.isMouseDown && !canvasEx) || (canvasEx && this.state.isMouseDown && !canvasEx.getActiveObject())) {
            const w = e.target.closest(".plan-slices-modal-content-block").querySelector('img').width || e.touches[0].target.closest(".plan-slices-modal-content-block").querySelector('img').width;
            const h = e.target.closest(".plan-slices-modal-content-block").querySelector('img').height || e.touches[0].target.closest(".plan-slices-modal-content-block").querySelector('img').height;
            if (e.clientX === undefined) {
                this.drawBaseRect(e.touches[0].clientX, e.touches[0].clientY, w, h) // 0,08 constant for creating  
            } else if (e.clientX !== undefined) {
                this.drawBaseRect(e.clientX, e.clientY, w, h) // 0,08 constant for creating  
            }
        } else {
            const items = e.nativeEvent.target.closest(".plan-slices-modal-content-block").querySelectorAll('img') || e.touches[0].target.closest(".plan-slices-modal-content-block").querySelectorAll('img');
            this.changeBrightness(e, items)
        }
    }

    newRectangle = () => {
        const rxRect = {
            fill: '#ecf76c',
            opacity: 1,
            hasBorders: false,
            hasControls: false,
            selectable: true,
        }
        const rect = new fabric.Rect(rxRect);
        return rect;
    }

    prepareRect = () => {
        let {
            canvas
        } = this.props.planSlices;
        canvas = canvas[this.props.canvasID];
        if (canvas !== null) {
            let array = canvas.getObjects();
            array.forEach(element => {
                element.setShadow('rgba(0,0,0,0)');
            });
            canvas.add(this.newRectangle());
            this.saveCanvas(canvas);
        }
    }

    onMouseDown = e => {
        let {
            canvas,
            planeSliceEnable,
        } = this.props.planSlices;
        canvas = canvas[this.props.canvasID];
        if (e.button === 0) {
            this.setState({
                isMouseDown: true,
                isRightMouseDown: false,
                isBrigthnessLevel: false,
            });
            if (planeSliceEnable[this.props.canvasID]) {
                // Render rect     
                if (canvas && !canvas.getActiveObject()) {
                    this.prepareRect();
                } else if (canvas && canvas.getActiveObject()) {
                    this.props.scannerAction.changeStore('eraseGroup', false)
                    let array = canvas.getObjects();
                    array.forEach(obj => {
                        obj.setShadow('rgba(0, 0, 0, 0)')
                    });
                    let obj = canvas.getActiveObject();
                    obj.setShadow('0px 0px 15px rgba(255, 250, 50, 0.7)');
                    let resizingObject = null;
                    canvas.on("object:modified", e => {
                        if (resizingObject) {
                            resizingObject.set({
                                opacity: 1,
                            });
                        }
                        resizingObject = null;
                    });
                    canvas.on('object:scaling', e => {
                        if (!e.target.fill)
                            return;
                        let obj = e.target,
                            w = obj.width * obj.scaleX,
                            h = obj.height * obj.scaleY;
                        resizingObject = obj;
                        obj.set({
                            'height': h,
                            'width': w,
                            'scaleX': 1,
                            'scaleY': 1,
                            opacity: 1,
                            fill: e.target.fill
                        });

                    })
                }

                this.setState({
                    startPoint: {
                        startXPos: e.clientX,
                        startYPos: e.clientY,
                        height: e.target.height - 20,
                        width: e.target.width - 20,
                    },
                    point: {
                        x: e.nativeEvent.offsetX,
                        y: e.nativeEvent.offsetY,
                    }
                })
            }
        } else if (e.button === 2) {
            this.props.planSlicesAction.disablePlanslice(this.props.canvasID)
            this.setState({
                isRightMouseDown: true,
                isMouseDown: false,
                isBrigthnessLevel: true,
                startPoint: {
                    startXPos: e.clientX,
                    startYPos: e.clientY,
                }
            })
        } else if (e.touches[0].clientX) {
            this.setState({
                isMouseDown: true,
                isRightMouseDown: false,
                isBrigthnessLevel: false,
            });

            if (planeSliceEnable[this.props.canvasID]) {
                // Render rect     
                if (canvas && !canvas.getActiveObject()) {
                    this.prepareRect();
                } else if (canvas && canvas.getActiveObject()) {
                    this.props.scannerAction.changeStore('eraseGroup', false)
                    let array = canvas.getObjects();
                    array.forEach(obj => {
                        obj.setShadow('rgba(0, 0, 0, 0)')
                    });
                    let obj = canvas.getActiveObject();
                    obj.setShadow('0px 0px 15px rgba(255, 250, 50, 0.7)');
                }

                var rect = e.target.getBoundingClientRect();
                let x = e.targetTouches[0].pageX - rect.left;
                let y = e.targetTouches[0].pageY - rect.top;

                this.setState({
                    startPoint: {
                        startXPos: e.touches[0].clientX,
                        startYPos: e.touches[0].clientY,
                        height: e.touches[0].target.height - 20,
                        width: e.touches[0].target.width - 20
                    },
                    point: {
                        x: x,
                        y: y,
                    }
                })
            }
        }


    }

    onMouseUp = () => {
        const {
            planeSliceEnable
        } = this.props.planSlices;
        let {
            canvas
        } = this.props.planSlices;
        canvas = canvas[this.props.canvasID];
        this.props.planSlicesAction.enablePlanslice(this.props.canvasID)
        this.setState({
            isMouseDown: false,
            isRightMouseDown: false,
        })

        if (planeSliceEnable[this.props.canvasID]) {
            this.setState({
                renderedPlan: true,
                direction: null,
            })
            // // Change position
            if (canvas !== null) {
                if (canvas.getActiveObject()) {
                    // color active selection
                    let array = canvas.getObjects();
                    array.forEach(obj => {
                        obj.setShadow('rgba(0, 0, 0, 0)')
                    });
                    // this.getCoords(obj.calcCoords())
                    let obj = canvas.getActiveObject();
                    this.getCoords(obj.calcCoords())

                    obj.setShadow('0px 0px 15px rgba(255, 250, 50, 0.7)');

                    this.props.scannerAction.changeStore('eraseGroup', false)
                    this.props.scannerAction.changeStore('changeAngle', false)

                    this.changeObjPosition()
                } else {
                    this.props.scannerAction.changeStore('eraseGroup', false)
                    this.props.scannerAction.changeStore('eraseAll', false)
                    this.props.scannerAction.changeStore('changeAngle', false)
                }
            }
        } else {
            this.setState({
                isBrigthnessLevel: false
            })
        }
    }

    getCoords = (coords) => {
        let objCoords = {}
        if (coords.mt !== undefined && coords.mt !== 0) {
            objCoords = {
                s: Math.abs(Number(125 - coords.mt.y).toFixed()),
                i: Math.abs(Number(125 - coords.mb.y).toFixed()),
                p: Math.abs(Number(coords.mr.x - 125).toFixed()),
                a: Math.abs(Number(coords.ml.x - 125).toFixed()),
                l: Math.sqrt(Math.pow(Number(coords.ml.x - 125).toFixed(), 2) + Math.pow(Number(125 - coords.mt.y).toFixed(), 2)).toFixed(),
                r: Math.sqrt(Math.pow(Number(coords.mr.x - 125).toFixed(), 2) + Math.pow(Number(125 - coords.mb.y).toFixed(), 2)).toFixed(),
            }
        } else {
            objCoords = {
                s: '00',
                i: '00',
                p: '00',
                a: '00',
                l: '00',
                r: '00',
            }
        }
        this.props.coords.setCoords(objCoords)
    }

    onContextMenu = e => {
        e.preventDefault();
    }

    render() {
        return (
            <div className="plan-slices-dashboard"
                onMouseUp={this.onMouseUp}
                onTouchEnd={e => { e.preventDefault(); this.onMouseUp(e) }}
                onMouseMove={this.onMouseMove}
                onTouchMove={e => { e.preventDefault(); this.onMouseMove(e) }}
                onMouseDown={this.onMouseDown}
                onTouchStart={e => { this.onMouseDown(e) }}
                onContextMenu={this.onContextMenu}
            // onMouseLeave={this.onMouseUp}
            >
                <canvas id={this.props.canvasID} width="250" height="250" />
            </div>
        )
    }
}

const mapStateToProps = ({
    scanner,
    planSlices,
}) => ({
    scanner,
    planSlices,
});
const mapDispatchToProps = dispatch => ({
    planSlicesAction: bindActionCreators({
        canvasUpdateGraphicrx,
        enablePlanslice,
        disablePlanslice,
        setActiveViewport,
        changePlanslicesStore
    }, dispatch),
    scannerAction: bindActionCreators({ changeStore }, dispatch),
    coords: bindActionCreators({ setCoords }, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PlanSlicesImage);