import BaseApiService from './base';

export class CustomerApiService extends BaseApiService {
  // eslint-disable-next-line
  constructor() {
    super();
  }

  getAll() {
    const headers = {
      Authorization: `Bearer ${window.localStorage.authToken}`,
      app: 'mri'
    };

    return this.request({
      method: 'get',
      url: 'organizations',
      headers
    });
  }
}