import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import uid from 'uuid';
import {
  storeSeriesFiles,
  storeMipSeriesFiles,
  disablePlanslice,
  changeStore,
  selectActiveSerie,
  storeSequence,
  newLocalSerieId,
  cancelScan
} from '../../actions';
import { Dropdown, Button, Grid, Modal } from 'semantic-ui-react';
import { Api } from '../../services/api';
import './ReconstructionNext.css';
import { fabric } from 'fabric';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Image
} from 'pure-react-carousel';
import reconImg from '../../images/reconstructions.jpg'


class ReconstructionNext extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      canvasReconstruction: null,
      brightness: 100,
      contrast: 100,
      opacity: 0,
      rectW: null,
      rectH: null,
      rectT: null,
      rectL: null,
      isPlaying: false,
      eraseDisabled: true,
      applyDisabled: true,
      localSerieId: {
        id: [],
        images: [],
        plane: [],
        imagePlanes: [],
      },
      newLocalSerieId: {
        id: [],
        images: [],
        plane: [],
        imagePlanes: [],
      },
      newSequenceState: [],
      newSequenceNumber: 0,
      newActiveSequence: 0,
      mipIsVisibleModal: false,
      mipRender: false,
      render: false,
      imageStep: 0,
      additionalTimer: '',
      showErrorModal: false,
    }
  }

  componentDidUpdate(prevProps) {
    // dropdown functionality
    if (prevProps.scanner.sequenceState !== this.props.scanner.sequenceState) {
      const sequence = this.props.scanner.sequenceState.map(el => el);
      sequence.shift()
      this.setState({
        newSequenceState: sequence,
      })
    }
    if (prevProps.scanner.sequenceNumber !== this.props.scanner.sequenceNumber) {
      this.setState({
        newSequenceNumber: this.props.scanner.sequenceNumber,
      })
    }
    if (prevProps.scanner.activeSequence !== this.props.scanner.activeSequence) {
      this.setState({
        newActiveSequence: this.props.scanner.activeSequence
      })
    }

    let object = this.state.localSerieId;

    let i = prevProps.scanner.sequenceNumber;
    let id = this.props.scanner.activeSerie.id;

    if (i !== undefined && id !== undefined) {
      const r = object.id.findIndex(el => el === id);
      if (r === -1 && prevProps.scanner.enableStartScan) {
        object.id.push(id);
        object.images.push(this.props.scanner.localSerie.images);
        object.plane.push(this.props.scanner.localSerie.plane)
        prevProps.scanActions.changeStore('activeSequence', object.images.length - 1);
        this.setState({ localSerieId: object })
      }
    }

    let { localSerieId } = this.state
    let { imagePlanes } = this.state.localSerieId
    if (prevProps.scanner.files.images !== this.props.scanner.files.images) {
      imagePlanes.push(this.props.scanner.files.images.map(el => {
        let plane = '';
        switch (el.planeType) {
          case '1': plane = 'Coronal'; break;
          case '2': plane = 'Sagittal'; break;
          case '3': plane = 'Axial'; break;
          default: plane = this.props.scanner.localSerie.plane;
        }
        return el = plane
      }))
      this.setState({
        localSerieId: {
          ...this.state.localSerieId,
          imagePlanes
        }
      })
    } else if (prevProps.scanner.newLocalSerieIdImages !== this.props.scanner.newLocalSerieIdImages) {
      localSerieId.imagePlanes.push(this.props.scanner.newLocalSerieIdImages.images.map(el => {
        let plane = '';
        switch (el.planeType) {
          case '1': plane = 'Coronal'; break;
          case '2': plane = 'Sagittal'; break;
          case '3': plane = 'Axial'; break;
          default: plane = this.props.scanner.localSerie.plane;
        }
        return el = plane
      }))
      this.setState({
        localSerieId: {
          ...this.state.localSerieId,
          imagePlanes
        }
      })
    }

    if (prevProps.scanner.examEnded !== this.props.scanner.examEnded) {
      if (this.props.scanner.examEnded) {
        this.setState({
          localSerieId: {
            id: [],
            images: [],
            plane: [],
            imagePlanes: [],
          },
          newActiveSequence: 0,
        });
      }
    }

  }

  handleChange = (e, { name, value }) => {
    let imagesIndex = this.state.localSerieId.id.findIndex(el => el === value);
    clearInterval(this.state.additionalTimer);
    this.setState({ 'imageStep': 0 });
    this.setState({ 'newActiveSequence': imagesIndex });
  }

  drawCanvas() {
    let canvas = this.state.canvasReconstruction;
    if (canvas === null)
      canvas = new fabric.Canvas('recon-draw', {
        selection: false
      })

    canvas.isDrawingMode = true;
    canvas.freeDrawingBrush.color = "yellow";
    canvas.freeDrawingBrush.width = 5;

    canvas.renderAll();

    if (this.state.canvasReconstruction !== null) {
      this.setState({ isPlaying: false, canvasReconstruction: null })
      canvas.clear();
    } else {
      canvas.on('mouse:up', () => this.setState({ eraseDisabled: false }))
    }
    this.setState({ canvasReconstruction: canvas })
  }

  renderSeriesImages = () => {
    const activeSequence = this.state.newActiveSequence;
    let images = this.state.localSerieId.images[activeSequence];
    const sequence = this.state.newSequenceState;
    return (
      <div className='recon-image-container'>
        <div className='recon-canvas-container'>
          <canvas id='recon-draw' width="400" height="400" />
        </div>
        <CarouselProvider
          currentSlide={this.state.imageStep}
          dragEnabled={false}
          touchEnabled={false}
          naturalSlideWidth={100}
          naturalSlideHeight={100}
          totalSlides={images.length}
          interval={2200}>

          <Slider classNameAnimation='null' >
            {images.map((image, index) => (
              <Slide index={image.id} key={index}>
                <Image
                  src={image}
                  draggable={false}
                  onError={() => { console.log('Error: Image fails to load'); this.props.scanActions.cancelScan() }}
                  style={{ filter: `brightness(${this.state.brightness}%) contrast(${this.state.contrast}%)` }}
                />
                <div
                  className='zoomed-slide-white-overlay'
                  style={{ filter: `opacity(${this.state.opacity}%)` }}
                />
              </Slide>
            ))}
          </Slider>

          <div className='autoview-button-block recon-image-buttons'>
            <ButtonBack
              id='recon-prior-button'
              onClick={() => { this.setState({ imageStep: this.state.imageStep - 1 }) }}>
              Prior
                </ButtonBack>
            <ButtonNext
              id='recon-next-button'
              onClick={() => { this.setState({ imageStep: this.state.imageStep + 1 }) }}>
              Next
                </ButtonNext>
            <Dropdown
              id='recon-dropdown'
              disabled={sequence.length <= 0}
              compact
              selection
              options={sequence}
              value={sequence[activeSequence].value}
              onChange={this.handleChange}
            />
          </div>
        </CarouselProvider>
      </div>
    );
  };

  renderEmptyImage = () => {
    return (
      <div>
        <div className="empty-image" />
      </div>
    );
  };

  handleDraw = () => {
    let canvas = this.state.canvasReconstruction;
    let draw = document.querySelector('.draw-button');
    if (draw.innerHTML === 'Begin Draw') {
      this.drawCanvas();
      draw.innerHTML = 'End Draw';
      this.setState({ applyDisabled: true })
    } else {
      canvas.isDrawingMode = false;
      let obj = canvas.getObjects();
      if (obj.length !== 0) {
        obj[0].set({
          hasControls: false,
          selection: false,
          lockMovementX: true,
          lockMovementY: true,
        });
        this.setState({
          rectW: obj[0].getScaledWidth(),
          rectH: obj[0].getScaledHeight(),
          rectT: obj[0].top,
          rectL: obj[0].left,
        });
        this.setState({ eraseDisabled: true, applyDisabled: false })
      }
      draw.innerHTML = 'Begin Draw';
    }
  }

  handleApply = async () => {
    let canvas = this.state.canvasReconstruction;

    canvas.clear();

    let { localSerieId } = this.state
    let i = this.state.newSequenceNumber;
    let id = this.randomIntFromInterval(1000, 1500);
    let apply = document.querySelectorAll('.recon-navigation-bottom > button')[0]

    let neededPlane = this.props.scanner.localSerie.plane
    if (this.props.scanner.localSerie.plane === 'Multiplane') {
      neededPlane = this.state.localSerieId.imagePlanes[1][this.state.imageStep]
    }

    let mipSerie = this.props.scanner.mipSeries.find(el => el.plane === neededPlane && el.type === 'Reconstruction');
    if (mipSerie !== undefined) {
      this.props.scanActions.storeMipSeriesFiles(mipSerie);
      localSerieId.id.push(id)
      localSerieId.images.push(mipSerie.images)
      localSerieId.plane.push(mipSerie.plane)
      i++
      this.setState({
        localSerieId,
        imageStep: 0,
        newActiveSequence: localSerieId.images.length - 1,
        newSequenceNumber: i,
        newSequenceState: [...this.state.newSequenceState, { text: i, value: id }]
      })

      let s = 0;
      const timer = setInterval(() => {
        this.setState({ 'imageStep': s++ })
        if (s === mipSerie.images.length) {
          clearInterval(this.state.additionalTimer);
        }
      }, 1000);
      this.setState({ additionalTimer: timer })

    } else {
      this.setState({ showErrorModal: !this.state.showErrorModal })
    }

    apply.disabled = true;
    this.setState({ isPlaying: true })

  }

  handleErase = () => {
    this.state.canvasReconstruction.clear();
  }

  randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  handleShowModal = () => {
    this.setState({
      mipIsVisibleModal: true,
      isPlaying: false,
      applyDisabled: true,
      // currentSlide: this.props.scanner.imageStep,
      brightness: this.props.scanner.brightness,
      contrast: this.props.scanner.contrast,
      opacity: this.props.scanner.opacity,
      imageStep: this.props.scanner.imageStep,
      canvasReconstruction: null,
    })
    this.props.scanActions.changeStore('modalIsOpen', true)
  }

  handleCloseModal = () => {
    let { newSequenceState } = this.state
    newSequenceState.unshift({})

    let neededPlane = this.props.scanner.localSerie.plane
    if (this.props.scanner.localSerie.plane === 'Multiplane') {
      neededPlane = this.state.localSerieId.imagePlanes[1][this.state.imageStep]
    }

    let mipSerie = this.props.scanner.mipSeries.find(el => el.plane === neededPlane && el.type === 'Reconstruction');
    this.setState({ mipIsVisibleModal: false, mipRender: false, mipImages: [] })
    this.props.scanActions.changeStore('modalIsOpen', false)
    this.props.scanActions.changeStore('sequenceState', newSequenceState)
    this.props.scanActions.changeStore('sequenceNumber', this.state.newSequenceNumber)
    this.props.scanActions.changeStore('activeSequence', this.state.newActiveSequence)
    this.props.scanActions.newLocalSerieId(this.state.localSerieId)
    clearInterval(this.state.additionalTimer);

    // this.props.scanActions.changeStore('imageStep', this.state.imageStep)
    this.props.scanActions.changeStore('imageStep', mipSerie.images.length - 1)

  }

  onClose = () => {
    this.setState({ showErrorModal: false })
  }

  render() {
    const {
      scan: { isRunning, cancelled },
      files: { images }
    } = this.props.scanner;
    return (
      <React.Fragment>
        <Grid.Column>
          <Button disabled={!images.length || isRunning || cancelled}
            onClick={() => this.handleShowModal()}
          >
            {(images.length && !isRunning && !cancelled)
              ? <img src={reconImg} alt={reconImg} />
              : ''}
          </Button>
          <div className="button-caption">MIP</div>
        </Grid.Column>
        <Grid.Column>
          <Button disabled />
        </Grid.Column>

        <Modal
          size="tiny"
          onClose={() => this.handleCloseModal()}
          open={this.state.mipIsVisibleModal}
          className='recon-modal'
          closeIcon
          closeOnDimmerClick={false}>

          <Modal.Header>Reconstruction</Modal.Header>
          <Modal.Content>
            <h4 className='recon-modal-content-header'>Autoview</h4>
            {(images.length && !isRunning && !cancelled)
              ? this.renderSeriesImages()
              : null}

            <div className='recon-navigation-bottom'>
              <button disabled={this.state.applyDisabled} onClick={() => this.handleApply()}>Apply</button>
              <button className='draw-button' onClick={() => this.handleDraw()}>Begin Draw</button>
              <button disabled={this.state.eraseDisabled} onClick={() => this.handleErase()}>Erase</button>
            </div>
          </Modal.Content>
        </Modal>

        <Modal
          open={this.state.showErrorModal}
          className='error-modal-message'
        >
          <Modal.Content>
            <p>There are no reconstructions available for current sequence</p>
            <button onClick={this.onClose}>OK</button>
          </Modal.Content>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ scanner, planSlices }) => ({ scanner, planSlices });
const mapDispatchToProps = dispatch => ({
  scanActions: bindActionCreators({
    storeSeriesFiles,
    disablePlanslice,
    changeStore,
    selectActiveSerie,
    storeSequence,
    storeMipSeriesFiles,
    newLocalSerieId,
    cancelScan
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ReconstructionNext);