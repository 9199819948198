import React, { Component } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Form, Header, Button, Grid, Modal, Image, Dropdown, Icon } from "semantic-ui-react";
import { changeLocalSerieField, enablePlanslice, canvasUpdateGraphicrx, enableSat, checkModal, changeStore, syncSeries } from "../../actions";
import ReconstructionNext from '../reconstruction/ReconstructionNext'
import { CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import { fabric } from 'fabric';
import { defaultState } from '../../utils/GraphicRxDefaultState'
import ViewportWindow from './viewportWindow'
// import AutoViewModal from '../autoView/AutoViewModal'
import satImg from '../../images/satPulse.jpg'
import rxImg from '../../images/graphicRx.jpg'
import Draggable from 'react-draggable';

import horizontalImg from '../../images/horizontal-pattern.png'
import verticalImg from '../../images/vertical-pattern.png'

import "./styles.css";
import AutoviewDraggable from '../autoView/AutoviewDraggable';

const defaultPositionX = 695
const defaultPositionY = -525
const multiplaneType = 'Multiplane'
const sagittalType = 'Sagittal'
const coronalType = 'Coronal'
const axialType = 'Axial'

class AdditionalParameters extends Component {
	state = {
		localSerieId: {
			id: [],
			images: [],
			plane: [],
			imagePlanes: [],
		},
		horizontalPattern: new fabric.Pattern({
			source: horizontalImg,
			repeat: 'repeat'
		}),
		verticalPattern: new fabric.Pattern({
			source: verticalImg,
			repeat: 'repeat'
		}),
		imagesSettingsModal: {
			isVisible: false,
			title: "GraphicRx Panel",
			mode: "CopyRx", // SAT or CopyRx
			currentImageId: 0,
			images: [{
				url:
					"https://pbs.twimg.com/profile_images/900338165113815045/aA0Wx0uR_400x400.jpg",
				rotate: 0,
				scale: 1
			}]
		},
		imageStep: 0,
		newActiveSequence: 0,
		zoomValue: 0,
		graphicRx1: {
			canvasId: 'graphicRx1',
			zoomValue: 0,
			currentImage: 0,
			totalSlides: 0,
			slidesArray: [],
			activeSequence: 0,
			activeSequenceOption: {},
			sequencePlane: '',
			prevSequencePlane: '',
			prevSlices: [],
		},
		graphicRx2: {
			canvasId: 'graphicRx2',
			zoomValue: 0,
			currentImage: 0,
			totalSlides: 0,
			slidesArray: [],
			activeSequence: null,
			activeSequenceOption: {},
			sequencePlane: '',
			prevSequencePlane: '',
			prevSlices: [],
		},
		graphicRx3: {
			canvasId: 'graphicRx3',
			zoomValue: 0,
			currentImage: 0,
			totalSlides: 0,
			slidesArray: [],
			activeSequence: null,
			activeSequenceOption: {},
			sequencePlane: '',
			prevSequencePlane: '',
			prevSlices: [],
		},
		lastSlices: [],
		lastSlicesPlane: [],
		scanTimings: [],
		initialScanTimings: [],
		showAutoview: 0,
		currentX: 0,
		currentY: 0,
		initialX: 0,
		initialY: 0,
		xOffset: 0,
		yOffset: 0,
		LBautoview: false,
		fatWaterField: '',
		endTimer: 0,
		objCoords: {
			s: '00',
			i: '00',
			p: '00',
			a: '00',
			l: '00',
			r: '00',
		},
		objWidth: {},
		objHeight: {},
		objCloned: {},
		fov: null,
		frequencyDirection: null,
		sliceThickness: null,
		sliceSpacing: null,
		tr: null,
		te: null,
		nex: null,
		prevActiveVW: null,
		draggableOffset: {
			left: '',
			top: '',
			right: '',
			bottom: '',
		},
		newSerie: false,
		deltaPosition: {
			x: 0, y: 0
		},
		topDrag: Number,
		botDrag: Number,
		leftDrag: Number,
		rightDrag: Number,
	};

	componentDidMount() {
		this.setState({
			scanTimings: defaultState,
			initialScanTimings: defaultState,
			fatWaterField: this.props.scanner.fatWaterField,
		})
		let canvas = new fabric.Canvas(this.props.canvasID, {
			selection: false,
		});
		canvas.setHeight(250);
		canvas.setWidth(250);
		this.props.planSlicesAction.canvasUpdateGraphicrx('graphicRx1', canvas);
	}

	getEndTimer = () => {
		let result = ((this.state.scanTimings[5].value * this.props.scanner.localSerie.phaseMatrix * this.state.scanTimings[7].value) / this.props.scanner.localSerie.reductionFactor) / 1000;
		if (isNaN(result)) result = 0;
		if (result < 1 && result > 0) result = 1;

		return Number(result * 0.01).toFixed(2)
	}

	componentDidUpdate(prevProps, prevState) {
		// dropdown functionality
		let activeViewport = this.props.planSlices.oneActiveVW[0]
		if (prevProps.coords.objCoords !== this.props.coords.objCoords) {
			this.setState({
				objCoords: this.props.coords.objCoords
			})
		}
		if (prevProps.scanner.localSerie !== this.props.scanner.localSerie) {
			this.setState({ endTimer: this.getEndTimer(this.props.scanner.localSerie) })
		}
		if (prevProps.scanner.newLocalSerieId !== this.props.scanner.newLocalSerieId) {
			this.setState({ localSerieId: this.props.scanner.newLocalSerieId })
		}

		if (prevProps.scanner.activeSerie !== this.props.scanner.activeSerie) {
			let prevId = prevProps.scanner.activeSerie.id
			let thisId = this.props.scanner.activeSerie.id
			if (prevProps.scanner.activeSerie.id !== undefined && prevId !== thisId) {
				this.setState({
					newSerie: true
				})
			} else if (prevId === thisId) {
				this.setState({
					newSerie: false
				})
			}
		}

		if (prevProps.scanner.activeSequence !== this.props.scanner.activeSequence) {
			let { canvas } = this.props.planSlices
			let { graphicRx1, graphicRx2, graphicRx3 } = this.state
			graphicRx1 = {
				...this.state.graphicRx1,
				currentImage: this.props.scanner.imageStep,
				activeSequence: this.props.scanner.activeSequence,
				activeSequenceOption: this.props.scanner.sequenceState[this.props.scanner.sequenceState.length - 1]
			}
			let allRxState = [graphicRx1, graphicRx2, graphicRx3].filter(el => canvas[el.canvasId] !== null)
			if (canvas['graphicRx1'] !== null) {
				graphicRx1.prevSlices = canvas['graphicRx1'].getObjects().filter(el => el.hasControls)
				graphicRx1.prevSequencePlane = this.state.localSerieId.plane[this.state.localSerieId.plane.length - 2]
			}
			allRxState.forEach(item => {
				let canvasEx = canvas[item.canvasId]
				canvasEx.clear();
				canvasEx.renderAll();
				this.props.planSlicesAction.canvasUpdateGraphicrx(item.canvasId, canvasEx);
			})
			this.setState({ newActiveSequence: this.props.scanner.activeSequence, graphicRx1, graphicRx2, graphicRx3 })
		}
		if (prevProps.scanner.fatWaterField !== this.props.scanner.fatWaterField) {
			let array = this.props.scanner.fatWaterField.split(', ')
			this.setState({ fatWaterField: array })
		}

		let object = this.state.localSerieId;

		if (prevProps.planSlices.oneActiveVW !== this.props.planSlices.oneActiveVW) {
			this.slidesArray(activeViewport)
			this.setState({ prevActiveVW: prevProps.planSlices.oneActiveVW[0] })
		}

		let i = prevProps.scanner.sequenceNumber;
		let id = this.props.scanner.activeSerie.id;
		let sequence = this.props.scanner.sequenceState.map(el => el);
		sequence.shift()
		if (i !== undefined && id !== undefined) {
			const r = object.id.findIndex(el => el === id);
			if (r === -1 && prevProps.scanner.enableStartScan) {
				object.id.push(id);
				object.images.push(this.props.scanner.localSerie.images);
				object.plane.push(this.props.scanner.localSerie.plane)
				let newState = this.state.graphicRx1
				newState.activeSequence = object.images.length - 1
				prevProps.scannerAction.changeStore('activeSequence', object.images.length - 1);
				this.setState({ localSerieId: object, graphicRx1: newState }, () => {
					let newState = this.state.graphicRx1
					newState.currentImage = this.props.scanner.localSerie.images.length - 1
					newState.activeSequenceOption = { value: id }
					newState.sequencePlane = this.props.scanner.localSerie.plane
					this.setState({ graphicRx1: newState })
				})
			}
		}

		let { imagePlanes } = this.state.localSerieId
		if (prevProps.scanner.files.images !== this.props.scanner.files.images && this.props.scanner.files.images.length > 0) {
			imagePlanes.push(this.props.scanner.files.images.map(el => {
				let plane = '';
				switch (el.planeType) {
					case '1': plane = coronalType; break;
					case '2': plane = sagittalType; break;
					case '3': plane = axialType; break;
					default: plane = this.props.scanner.localSerie.plane;
				}
				return el = plane
			}))
			this.setState({
				localSerieId: {
					...this.state.localSerieId,
					imagePlanes
				}
			})
		}
		///////////////////
		const { scanTimings } = this.state;
		if (prevProps.scanner.localSerie !== this.props.scanner.localSerie) {
			for (let i = 0; i < scanTimings.length; i++) {
				const element = scanTimings[i];
				if (this.props.scanner.localSerie[element.name] !== null && this.props.scanner.localSerie[element.name] !== undefined) {
					if (isNaN(Number(this.props.scanner.localSerie[element.name]))) {
						element.type = 'text'
						element.value = this.props.scanner.localSerie[element.name];
					} else {
						element.type = 'number'
						element.value = this.props.scanner.localSerie[element.name];
					}

					let elName = element.name;
					let titleChart = elName[0].toUpperCase() + elName.slice(1);

					element.min = this.props.scanner.localSerie['min' + titleChart];
					element.max = this.props.scanner.localSerie['max' + titleChart];
				} else {
					element.value = 0;
					element.max = '';
					element.min = '';
				}
			}
			this.setState({
				scanTimings: [...scanTimings],
				endTimer: this.getEndTimer(),
			});
		}

		if (prevProps.scanner.examEnded !== this.props.scanner.examEnded) {
			if (this.props.scanner.examEnded) {
				let canvas = new fabric.Canvas(this.props.canvasID, {
					selection: false,
				});
				canvas.setHeight(250);
				canvas.setWidth(250);
				this.props.planSlicesAction.canvasUpdateGraphicrx('graphicRx1', canvas);
				this.setState({
					localSerieId: {
						id: [],
						images: [],
						plane: [],
						imagePlanes: [],
					},
					scanTimings: defaultState,
					graphicRx1: {
						canvasId: 'graphicRx1',
						zoomValue: 0,
						currentImage: 0,
						totalSlides: 0,
						slidesArray: [],
						activeSequence: 0,
						activeSequenceOption: {},
						sequencePlane: '',
						prevSequencePlane: '',
						prevSlices: [],
					},
					graphicRx2: {
						canvasId: 'graphicRx2',
						zoomValue: 0,
						currentImage: 0,
						totalSlides: 0,
						slidesArray: [],
						activeSequence: null,
						activeSequenceOption: {},
						sequencePlane: '',
						prevSequencePlane: '',
						prevSlices: [],
					},
					graphicRx3: {
						canvasId: 'graphicRx3',
						zoomValue: 0,
						currentImage: 0,
						totalSlides: 0,
						slidesArray: [],
						activeSequence: null,
						activeSequenceOption: {},
						sequencePlane: '',
						prevSequencePlane: '',
						prevSlices: [],
					},
					lastSlices: [],
					lastSlicesPlane: [],
				});
			}
		}
	}
	////////////////////////////////////
	renderSeriesImage = () => {
		const activeSequence = this.props.scanner.activeSequence;
		const images = this.state.localSerieId.images[activeSequence];
		if (images !== undefined) {
			return (
				<CarouselProvider
					currentSlide={this.state.imageStep}
					dragEnabled={false}
					touchEnabled={false}
					naturalSlideWidth={50}
					naturalSlideHeight={50}
					totalSlides={images.length}
				>
					<div className='plan-slices-slider-container'>
						<Slider classNameAnimation='null' >
							{images.map((image, index) => (
								<Slide index={image.id} key={index}>
									<Image
										src={image}
										draggable={false}
									/>
								</Slide>
							))}
						</Slider>
					</div>
				</CarouselProvider>
			);
		}
	};

	addGroup = () => {
		let { canvas } = this.props.planSlices
		let activeViewport = this.props.planSlices.oneActiveVW[0]
		let activeCanvas = canvas[activeViewport]
		let activeElement = activeCanvas.getActiveObject()
		activeElement.set('id', 'added-group')
		const { graphicRx1, graphicRx2, graphicRx3, localSerieId } = this.state
		let allRxState = [graphicRx1, graphicRx2, graphicRx3].filter(el => canvas[el.canvasId] !== null && el.canvasId !== activeViewport)

		allRxState.forEach(item => {

			const satRect = {
				fill: 'rgba(236, 255, 255, 0.1)',
				opacity: 0.8,
				stroke: '#ecf76c',
				strokeWidth: 3,
			}
			const optionsRect = {
				angle: activeElement.angle,
				height: activeElement.height,
				width: activeElement.width,
				top: activeElement.top,
				left: activeElement.left,
				hasControls: true,
				cornerColor: 'cyan',
				cornerSize: 8,
				transparentCorners: false,
				hasRotatingPoint: true,
				hasBorders: true,
				borderColor: '#ecf76c',
				rotatingPointOffset: 20,
				id: 'added-group'
			}

			const rect = new fabric.Rect(optionsRect);
			let canvasEx = canvas[item.canvasId]
			if (this.props.scanner.localSerie.plane === multiplaneType) {
				rect.set('fill', this.state.horizontalPattern)
			} else if (this.props.scanner.localSerie.plane === sagittalType) {
				switch (localSerieId.imagePlanes[item.activeSequence][item.currentImage]) {
					case sagittalType: rect.set(satRect); break;
					case coronalType: rect.set('fill', this.state.verticalPattern); break;
					case axialType: rect.set('fill', this.state.verticalPattern); break;
					default: console.log('default 1'); return;
				}
			} else if (this.props.scanner.localSerie.plane === coronalType) {
				switch (localSerieId.imagePlanes[item.activeSequence][item.currentImage]) {
					case sagittalType: rect.set('fill', this.state.verticalPattern); break;
					case coronalType: rect.set(satRect); break;
					case axialType: rect.set('fill', this.state.horizontalPattern); break;
					default: console.log('default 2'); return;
				}
			} else if (this.props.scanner.localSerie.plane === axialType) {
				switch (localSerieId.imagePlanes[item.activeSequence][item.currentImage]) {
					case sagittalType: rect.set('fill', this.state.horizontalPattern); break;
					case coronalType: rect.set('fill', this.state.horizontalPattern); break;
					case axialType: rect.set(satRect); break;
					default: console.log('default 3'); return;
				}
			}
			canvasEx.add(rect)
			this.props.planSlicesAction.canvasUpdateGraphicrx(item.canvasId, canvasEx)
		})
	}

	eraseGroup = () => {
		let { canvas } = this.props.planSlices;
		let canvasArray = [canvas['graphicRx1'], canvas['graphicRx2'], canvas['graphicRx3']].filter(el => el !== null)
		let activeViewport = this.props.planSlices.oneActiveVW[0]
		let activeObject = canvas[activeViewport].getActiveObject()
		canvasArray.forEach(elCanvas => {
			let array = elCanvas.getObjects()
			array.forEach(el => {
				if (el.id === activeObject.id) {
					elCanvas.remove(el);
					elCanvas.renderAll();
					this.props.planSlicesAction.canvasUpdateGraphicrx(elCanvas);
				}
			})
		})

		this.props.scannerAction.changeStore('objCoords', {})
		this.props.scannerAction.changeStore('changeAngle', true)
		this.props.scannerAction.changeStore('eraseGroup', true)
	}

	eraseAll = () => {
		const clearCanva = name => {
			let { canvas } = this.props.planSlices;
			canvas = canvas[name];
			if (canvas !== null) {
				canvas.clear();
				canvas.renderAll();
				this.props.planSlicesAction.canvasUpdateGraphicrx(name, canvas);
			}
		}

		clearCanva('graphicRx1')
		clearCanva('graphicRx2')
		clearCanva('graphicRx3')

		this.props.scannerAction.changeStore('objCoords', {})
		this.props.scannerAction.changeStore('changeAngle', true)
		this.props.scannerAction.changeStore('eraseAll', true)
		this.props.scannerAction.changeStore('eraseGroup', true)
	}

	chooseActiveState = name => {
		switch (name) {
			case 'graphicRx1': return this.state.graphicRx1;
			case 'graphicRx2': return this.state.graphicRx2;
			case 'graphicRx3': return this.state.graphicRx3;
			default: return this.state.graphicRx1;
		}
	}

	handlePriorClick = name => {
		let activeViewport = this.props.planSlices.oneActiveVW[0]
		let activeState = this.chooseActiveState(activeViewport)
		activeState.currentImage--
		this.setState({ [name]: activeState }, () => {
			this.addRxToViewports(activeViewport)
		})
	}

	handleNextClick = name => {
		let activeViewport = this.props.planSlices.oneActiveVW[0]
		let activeState = this.chooseActiveState(activeViewport)
		activeState.currentImage++
		this.setState({ [name]: activeState }, () => {
			this.addRxToViewports(activeViewport)
		})
	}

	handleChange = (e, { name, value }) => {
		let { canvas } = this.props.planSlices
		let { localSerieId } = this.state
		let activeViewport = this.props.planSlices.oneActiveVW[0]
		let activeCanvas = canvas[activeViewport]
		let newCanvas = new fabric.Canvas(this.props.canvasID, {
			selection: false,
		});
		fabric.Object.prototype.transparentCorners = false;
		newCanvas.setHeight(250);
		newCanvas.setWidth(250);

		if (activeCanvas === null) {
			this.props.planSlicesAction.canvasUpdateGraphicrx(activeViewport, newCanvas);
		}



		const changeDropdownValue = (name, value, text) => {
			let activeState = this.chooseActiveState(activeViewport)
			let imagesIndex = this.state.localSerieId.id.findIndex(el => el === value);
			let images = this.state.localSerieId.images[imagesIndex].length
			let sequencePlane = this.state.localSerieId.plane[imagesIndex]
			activeState = { ...activeState, currentImage: images - 1, totalSlides: images, activeSequence: imagesIndex, sequencePlane, activeSequenceOption: { text: text, value: value } }
			let canvasEx = activeCanvas === null ? newCanvas : activeCanvas
			const satRect = {
				fill: 'rgba(236, 255, 255, 0.1)',
				opacity: 0.8,
				stroke: '#ecf76c',
				strokeWidth: 3,
			}
			const optionsRect = {
				angle: 0,
				height: 230,
				width: 230,
				top: 10,
				left: 10,
				hasControls: true,
				cornerColor: 'cyan',
				cornerSize: 8,
				transparentCorners: false,
				hasRotatingPoint: true,
				hasBorders: true,
				borderColor: '#ecf76c',
				rotatingPointOffset: 20,
				id: `${activeViewport}-rect`
			}

			const rect = new fabric.Rect(optionsRect);
			let existingObjects = canvasEx.getObjects().filter(el => el.id === 'graphicRx1-rect' || el.id === 'graphicRx2-rect' || el.id === 'graphicRx3-rect')
			let isMultipane = localSerieId.plane[imagesIndex] === 'Multiplane' ? localSerieId.imagePlanes[imagesIndex][images - 1] : localSerieId.plane[imagesIndex]
			if (existingObjects[0] !== undefined) {
				let clonedObjects = existingObjects.map(rect => rect)
				clonedObjects.forEach(rect => {
					let clonedRect = fabric.util.object.clone(rect).set('strokeWidth', 0)
					canvasEx.remove(rect)
					if (this.props.scanner.localSerie.plane === multiplaneType) {
						clonedRect.set('fill', this.state.horizontalPattern)
					} else if (this.props.scanner.localSerie.plane === sagittalType) {
						switch (isMultipane) {
							case sagittalType: clonedRect.set(satRect); break;
							case coronalType: clonedRect.set('fill', this.state.verticalPattern); break;
							case axialType: clonedRect.set('fill', this.state.verticalPattern); break;
							default: console.log('default 1'); return;
						}
					} else if (this.props.scanner.localSerie.plane === coronalType) {
						switch (isMultipane) {
							case sagittalType: clonedRect.set('fill', this.state.verticalPattern); break;
							case coronalType: clonedRect.set(satRect); break;
							case axialType: clonedRect.set('fill', this.state.horizontalPattern); break;
							default: console.log('default 2'); return;
						}
					} else if (this.props.scanner.localSerie.plane === axialType) {
						switch (isMultipane) {
							case sagittalType: clonedRect.set('fill', this.state.horizontalPattern); break;
							case coronalType: clonedRect.set('fill', this.state.horizontalPattern); break;
							case axialType: clonedRect.set(satRect); break;
							default: console.log('default 3'); return;
						}
					}
					canvasEx.add(clonedRect)
					this.props.planSlicesAction.canvasUpdateGraphicrx(activeViewport, canvasEx);
				})
			} else if (localSerieId.plane[imagesIndex] !== 'Multiplane') {
				if (this.props.scanner.localSerie.plane === multiplaneType) {
					rect.set('fill', this.state.horizontalPattern)
				} else if (this.props.scanner.localSerie.plane === sagittalType) {
					switch (isMultipane) {
						case sagittalType: rect.set(satRect); break;
						case coronalType: rect.set('fill', this.state.verticalPattern); break;
						case axialType: rect.set('fill', this.state.verticalPattern); break;
						default: console.log('default 1'); return;
					}
				} else if (this.props.scanner.localSerie.plane === coronalType) {
					switch (isMultipane) {
						case sagittalType: rect.set('fill', this.state.verticalPattern); break;
						case coronalType: rect.set(satRect); break;
						case axialType: rect.set('fill', this.state.horizontalPattern); break;
						default: console.log('default 2'); return;
					}
				} else if (this.props.scanner.localSerie.plane === axialType) {
					switch (isMultipane) {
						case sagittalType: rect.set('fill', this.state.horizontalPattern); break;
						case coronalType: rect.set('fill', this.state.horizontalPattern); break;
						case axialType: rect.set(satRect); break;
						default: console.log('default 3'); return;
					}
				}
				canvasEx.add(rect)
				this.props.planSlicesAction.canvasUpdateGraphicrx(activeViewport, canvasEx);
			}
			this.setState({ [name]: activeState }, () => this.slidesArray(name))
		}
		changeDropdownValue(activeViewport, value, name)
	}

	handleImageChange = (e, { name, value }) => {
		let activeViewport = this.props.planSlices.oneActiveVW[0]
		let { localSerieId } = this.state
		const changeDropdownValue = (name, value, text) => {
			let activeState = this.chooseActiveState(activeViewport)
			activeState = { ...activeState, currentImage: value }
			this.setState({ [name]: activeState }, () => {
				if (localSerieId.plane[activeState.activeSequence] === 'Multiplane') {
					this.addRxToViewports(activeViewport)
				}
			})
		}
		changeDropdownValue(activeViewport, value, name)

	}

	slidesArray = name => {
		let activeViewport = this.props.planSlices.oneActiveVW[0]
		let activeState = this.chooseActiveState(activeViewport)
		let array = [];

		for (let i = 0; i < activeState.totalSlides; i++) {
			array.push({ text: i + 1, value: i })
		}
		activeState = { ...activeState, slidesArray: array }
		this.setState({ [name]: activeState })
	}

	handleZoom = (e, name) => {
		let activeViewport = this.props.planSlices.oneActiveVW[0]
		let activeState = this.chooseActiveState(activeViewport)
		activeState = { ...activeState, zoomValue: e.target.value }
		this.setState({ [name]: activeState })
	}

	resetZoom = (name) => {
		let activeViewport = this.props.planSlices.oneActiveVW[0]
		let activeState = this.chooseActiveState(activeViewport)
		activeState = { ...activeState, zoomValue: 0 }
		this.setState({ [name]: activeState })
	}

	setTotalSlides = (name, number) => {
		let activeState = this.chooseActiveState(name)
		activeState = { ...activeState, totalSlides: number }
		this.setState({ [name]: activeState })
	}

	handleInputChange(value, name) {
		let { scanTimings } = this.state
		let scanTimingsCopy = scanTimings.map(el => el)
		let index = scanTimingsCopy.findIndex(el => el.name === name)
		scanTimingsCopy[index].value = value
		this.saveNewValues(scanTimingsCopy)
		this.setState({
			[name]: value,
		})
	}

	saveNewValues = (scanTimings) => {
		this.setState({
			endTimer: this.getEndTimer(),
			scanTimings
		})
	}

	closeAutoview = () => {
		this.setState({ showAutoview: 0 })
	}

	showAutoview = () => {
		this.setState({
			showAutoview: this.state.showAutoview === 0 ? 1 : 0
		});
	}

	dragMouseDown(e) {
		const { xOffset, yOffset } = this.state
		this.setState({
			initialX: e.clientX - xOffset,
			initialY: e.clientY - yOffset,
			LBautoview: true,
		})
	}

	onMouseMoveAutoview(e) {
		const { currentX, currentY, initialX, initialY } = this.state
		const header = document.querySelector(".graphic-rx-autoview-header")
		let LBautoview = this.state.LBautoview
		e.preventDefault()
		if (LBautoview) {
			this.setState({
				currentX: e.clientX - initialX,
				currentY: e.clientY - initialY,
				xOffset: currentX,
				yOffset: currentY,
			}, () => {
				if (e.target === header) {
					this.setTranslate(currentX, currentY, header);
				}
			})

		}
	}

	setTranslate = (xPos, yPos, el) => {
		el.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0)"
	}

	onMouseUpAutoview(e) {
		const { currentX, currentY } = this.state
		this.setState({
			LBautoview: false,
			initialX: currentX,
			initialY: currentY,
		})
	}

	waterFatChange = name => {
		let array = []
		if (this.props.scanner.fatWaterField === '') {
			array = []
		} else {
			array = this.props.scanner.fatWaterField.split(', ')
		}
		if (array.includes(name)) {
			let newArray = array.filter(el => el !== name)
			this.props.scannerAction.changeStore('fatWaterField', newArray.join(', '))
		} else {
			array.push(name)
			this.props.scannerAction.changeStore('fatWaterField', array.join(', '))
		}
	}

	handleAccept = () => {
		const imagesSettingsModal = this.state.imagesSettingsModal;
		this.setState({
			imagesSettingsModal: {
				...imagesSettingsModal,
				isVisible: false
			}
		})
		this.props.planSlicesAction.checkModal(true)
		this.props.scannerAction.changeStore('modalIsOpen', false);
		this.props.scannerAction.syncSeries();

		let array = [
			{ name: 'fov', value: this.state.fov },
			{ name: 'frequencyDirection', value: this.state.frequencyDirection },
			{ name: 'sliceThickness', value: this.state.sliceThickness },
			{ name: 'sliceSpacing', value: this.state.sliceSpacing },
			{ name: 'tr', value: this.state.tr },
			{ name: 'te', value: this.state.te },
			{ name: 'nex', value: this.state.nex },
		]

		array.forEach(el => {
			if (el.value !== null) {
				this.props.scannerAction.changeLocalSerieField({
					[el.name]: el.value
				});
			}
		})

		let { canvas } = this.props.planSlices
		const { graphicRx1, graphicRx2, graphicRx3 } = this.state
		let allRxState = [graphicRx1, graphicRx2, graphicRx3].filter(el => canvas[el.canvasId] !== null)
		allRxState.forEach(item => {
			let canvasEx = canvas[item.canvasId]
			let prevSequencePlane = item.sequencePlane;
			let prevSlices = canvasEx.getObjects().filter(el => el.hasControls)
			let newState = { ...item, prevSlices, prevSequencePlane }
			this.setState({ [item.canvasId]: newState })
		})

	}

	copyRxClick = () => {
		let { canvas } = this.props.planSlices
		const { graphicRx1, graphicRx2, graphicRx3 } = this.state
		let allRxState = [graphicRx1, graphicRx2, graphicRx3].filter(el => canvas[el.canvasId] !== null)
		allRxState.forEach(item => {
			let canvasEx = canvas[item.canvasId]
			if (item.sequencePlane === item.prevSequencePlane) {
				item.prevSlices.forEach(el => {
					canvasEx.add(el)
					canvasEx.renderAll()
					this.props.planSlicesAction.canvasUpdateGraphicrx(item.canvasId, canvasEx);
				})
			}
		})
	}

	renderImagesSettingsModalModal() {
		const imagesSettingsModal = this.state.imagesSettingsModal;
		const sequence = this.props.scanner.sequenceState.map(el => el);
		sequence.shift()
		const activeSequence = this.state.newActiveSequence;
		const oneActiveVW = this.props.planSlices.oneActiveVW
		let activeViewport = this.props.planSlices.oneActiveVW[0]
		let activeState = this.chooseActiveState(activeViewport)
		let objCoords = this.state.objCoords
		if (sequence[activeSequence] !== undefined) {
			return (
				<Modal
					className={this.state.showAutoview === 1 ? 'plan-slices-modal-moved' : 'plan-slices-modal'}
					onClose={() => {
						this.setState({
							imagesSettingsModal: {
								...imagesSettingsModal,
								isVisible: false
							},
							showAutoview: 0,
							newSerie: false,
						})
						this.props.planSlicesAction.checkModal(true)
						this.props.scannerAction.changeStore('modalIsOpen', false);
					}}
					open={imagesSettingsModal.isVisible}
					closeIcon
					closeOnDimmerClick={false}
				>
					<Modal.Header>
						{imagesSettingsModal.title}
					</Modal.Header>
					<Modal.Content>
						<div className="plan-slices-modal-content">
							<div className='plan-slices-modal-content-block'>
								<div className='button-block-item'>
									<button
										className='button-block-item-main-buttons'
										onClick={this.addGroup}
									>
										Add Group
									</button>
									<button
										className='button-block-item-main-buttons'
										onClick={this.eraseGroup}
										disabled={this.props.scanner.eraseGroup}
									>
										Erase Group
									</button>
									<button
										className='button-block-item-main-buttons'
										onClick={this.eraseAll}
										disabled={this.props.scanner.eraseAll}
									>	Erase All
									</button>
									<div className="button-box-block">
										<button
											className='buttons-box'
											disabled
										>
											Tracker
									</button>
										<button
											className='buttons-box'
											disabled
										>
											Fallback to R0
									</button>
										<button
											className='buttons-box'
											onClick={() => this.resetZoom(oneActiveVW)}
											disabled={this.props.planSlices.oneActiveVW === ''}
										>
											Display Normal
									</button>
										<button
											className='buttons-box'
											onClick={() => this.showAutoview()}
										>
											Autoview
									</button>
									</div>

									<div className='zoom-input-block'>
										<input
											type="range"
											value={this.state[oneActiveVW] !== undefined ? this.state[this.props.planSlices.oneActiveVW].zoomValue : 0}
											onChange={e => this.handleZoom(e, this.props.planSlices.oneActiveVW)}
											disabled={this.props.planSlices.oneActiveVW === ''}
										/>
										<p>Zoom</p>
									</div>

									<span className="ui button floating dropdown dropdown-block-rx" style={{ width: '170px', textAlign: 'center' }} onClick={() => this.copyRxClick()}>Copy Rx</span>
									<Dropdown
										text='Select Series'
										floating
										button
										options={sequence}
										value={activeState.activeSequenceOption.value}
										className='dropdown-block-rx'
										icon={false}
										onChange={this.handleChange}
										selectOnBlur={false}
									/>

									<Dropdown
										text='Select Image'
										floating
										button
										options={activeState.slidesArray}
										className='dropdown-block-rx'
										icon={false}
										onChange={this.handleImageChange}
										selectOnBlur={false}
									/>

									<div className="change-image-block">
										<button
											onClick={() => this.handlePriorClick(oneActiveVW)}
											disabled={activeState.currentImage === 0}
											className='button-change-image'
										>
											-
										</button>
										<button
											onClick={() => this.handleNextClick(oneActiveVW)}
											className='button-change-image'
											disabled={activeState.currentImage === activeState.totalSlides - 1 || activeState.totalSlides === 0}
										>
											+
										</button>
									</div>
									<button
										className='accept-button button-block-item-main-buttons'
										onClick={() => { this.handleAccept() }}
									>
										Accept
									</button>

								</div>

								<div className="viewports-block">
									<ViewportWindow
										canvasID='graphicRx1'
										localSerieId={this.state.localSerieId}
										currentSlide={this.state.graphicRx1.currentImage}
										zoom={this.state.graphicRx1.zoomValue}
										totalSlides={this.setTotalSlides}
										activeSequence={this.state.graphicRx1.activeSequence}
									/>
									<ViewportWindow
										canvasID='graphicRx2'
										localSerieId={this.state.localSerieId}
										currentSlide={this.state.graphicRx2.currentImage}
										zoom={this.state.graphicRx2.zoomValue}
										totalSlides={this.setTotalSlides}
										activeSequence={this.state.graphicRx2.activeSequence}
									/>
									<div className="vieport-part-buttons">
										<div className="vieport-part-buttons-half">
											<div style={{ display: 'flex' }}>
												<button
													className='sat-rx-buttons'
													disabled={imagesSettingsModal.mode === 'SAT'}
													onClick={() => {
														this.setState({
															imagesSettingsModal: {
																...imagesSettingsModal,
																mode: 'SAT',
																title: 'Sat Pulse Panel'
															}
														})
														this.props.planSlicesAction.enableSat(true);
													}
													}
												>
													SAT
											</button>
												<button
													className='sat-rx-buttons'
													style={{ fontSize: '11px', lineHeight: 1 }}
													disabled={imagesSettingsModal.mode === 'CopyRx'}
													onClick={() => {
														this.setState({
															imagesSettingsModal: {
																...imagesSettingsModal,
																mode: 'CopyRx',
																title: 'GraphicRx Panel'
															}
														})
														this.props.planSlicesAction.enableSat(false);
													}
													}
												>
													Graphic Rx
											</button>
											</div>

											<ul className="fat-water-list">
												<li>
													<label>
														<input
															type="checkbox"
															checked={this.state.fatWaterField.includes('FSAT')}
															onChange={() => this.waterFatChange('FSAT')}
														/>
														Fat
													</label>
												</li>
												<li>
													<label>
														<input
															type="checkbox"
															checked={this.state.fatWaterField.includes('WSAT')}
															onChange={() => this.waterFatChange('WSAT')}
														/>
														Water
													</label>
												</li>
											</ul>
											<label style={{ paddingLeft: '5px' }}>Scan Time: {this.state.endTimer}</label>
										</div>
										<div className="vieport-part-buttons-half input-side">
											<table>
												<tbody>
													{this.state.scanTimings.map((item, index) => {
														return (
															<tr key={index}>
																<td className="td-title">{item.lable}</td>
																<td className="td-input">
																	<Form.Input
																		type={item.type}
																		name={item.name}
																		disabled={item.disabled}
																		onChange={e => this.handleInputChange(Number(e.target.value), item.name)}
																		value={index === 0 ? this.props.scanner.localSerie.plane : item.value}
																		min={item.min}
																		max={item.max}
																		label={item.label}
																		readOnly={index === 0}
																	/>
																</td>
															</tr>
														)
													})}
												</tbody>
											</table>
										</div>
										<div className="graphic-rx-bottom">
											<table style={{ borderCollapse: 'collapse' }}>
												<tbody>
													<tr>
														<td style={{ textAlign: 'right', paddingRight: '5px' }}>Start:</td>
														<td className="start-end-table-cell">S:{objCoords.s}</td>
														<td className="start-end-table-cell">L:{objCoords.l}</td>
														<td className="start-end-table-cell">P:{objCoords.p}</td>

													</tr>
													<tr>
														<td style={{ textAlign: 'right', paddingRight: '5px' }}>End:</td>
														<td className="start-end-table-cell">I:{objCoords.i}</td>
														<td className="start-end-table-cell">R:{objCoords.r}</td>
														<td className="start-end-table-cell">A:{objCoords.a}</td>
													</tr>
												</tbody>
											</table>
											<div>
												<span style={{ fontSize: '10px' }}># of Slices</span>
												<Form.Input
													type="text"
													className='slices-input'
													readOnly
													value={activeState.totalSlides}
												/>
											</div>
										</div>
									</div>
									<ViewportWindow
										canvasID='graphicRx3'
										localSerieId={this.state.localSerieId}
										currentSlide={this.state.graphicRx3.currentImage}
										zoom={this.state.graphicRx3.zoomValue}
										totalSlides={this.setTotalSlides}
										activeSequence={this.state.graphicRx3.activeSequence}
									/>
								</div>
							</div>
						</div>

					</Modal.Content>
					{this.state.showAutoview === 1 && <AutoviewDraggable
						localSerieId={this.state.localSerieId}
						showAutoview={this.state.showAutoview}
						closeAutoview={this.closeAutoview}
					/>}
				</Modal>
			);
		}
	}

	getCoords(elem) { // кроме IE8-
		let box = elem.getBoundingClientRect();
		this.setState({
			topDrag: (box.top + window.pageYOffset) - defaultPositionY,
			leftDrag: (box.left + window.pageXOffset) - defaultPositionX,
		})
		return {
			top: box.top + window.pageYOffset,
			left: box.left + window.pageXOffset
		};

	}

	handleClickRotateLeft() {
		const currentImage = this.state.imagesSettingsModal.images[this.state.imagesSettingsModal.currentImageId];
		currentImage.rotate += 90;
		this.setState({
			imagesSettingsModal: {
				...this.state.imagesSettingsModal,
				images: {
					...this.state.imagesSettingsModal.images,
					currentImage
				}
			}
		});
	}

	addRxToViewports = (activeViewport) => {
		let { canvas } = this.props.planSlices
		const { graphicRx1, graphicRx2, graphicRx3, localSerieId } = this.state
		let allRxState = activeViewport ? [graphicRx1, graphicRx2, graphicRx3].filter(el => el.canvasId === activeViewport)
			: [graphicRx1, graphicRx2, graphicRx3].filter(el => canvas[el.canvasId] !== null)

		allRxState.forEach(item => {

			const satRect = {
				fill: 'rgba(236, 255, 255, 0.1)',
				opacity: 0.8,
				stroke: '#ecf76c',
				strokeWidth: 3,
			}
			const optionsRect = {
				angle: 0,
				height: 230,
				width: 230,
				top: 10,
				left: 10,
				hasControls: true,
				cornerColor: 'cyan',
				cornerSize: 8,
				transparentCorners: false,
				hasRotatingPoint: true,
				hasBorders: true,
				borderColor: '#ecf76c',
				rotatingPointOffset: 20,
				id: `${item.canvasId}-rect`
			}

			const rect = new fabric.Rect(optionsRect);
			let canvasEx = canvas[item.canvasId]
			let existingObjects = canvasEx.getObjects().filter(el => el.id === 'graphicRx1-rect' || el.id === 'graphicRx2-rect' || el.id === 'graphicRx3-rect')
			let isMultipane = localSerieId.plane[item.activeSequence] === 'Multiplane' ? localSerieId.imagePlanes[item.activeSequence][item.currentImage] : localSerieId.plane[item.activeSequence]
			if (existingObjects[0] !== undefined) {
				canvasEx.clear()
				if (this.props.scanner.localSerie.plane === multiplaneType) {
					rect.set('fill', this.state.horizontalPattern)
				} else if (this.props.scanner.localSerie.plane === sagittalType) {
					switch (isMultipane) {
						case sagittalType: rect.set(satRect); break;
						case coronalType: rect.set('fill', this.state.verticalPattern); break;
						case axialType: rect.set('fill', this.state.verticalPattern); break;
						default: console.log('default 1'); return;
					}
				} else if (this.props.scanner.localSerie.plane === coronalType) {
					switch (isMultipane) {
						case sagittalType: rect.set('fill', this.state.verticalPattern); break;
						case coronalType: rect.set(satRect); break;
						case axialType: rect.set('fill', this.state.horizontalPattern); break;
						default: console.log('default 2'); return;
					}
				} else if (this.props.scanner.localSerie.plane === axialType) {
					switch (isMultipane) {
						case sagittalType: rect.set('fill', this.state.horizontalPattern); break;
						case coronalType: rect.set('fill', this.state.horizontalPattern); break;
						case axialType: rect.set(satRect); break;
						default: console.log('default 3'); return;
					}
				}
				canvasEx.add(rect)
				this.props.planSlicesAction.canvasUpdateGraphicrx(item.canvasId, canvasEx)
			} else {
				if (this.props.scanner.localSerie.plane === multiplaneType) {
					rect.set('fill', this.state.horizontalPattern)
				} else if (this.props.scanner.localSerie.plane === sagittalType) {
					switch (isMultipane) {
						case sagittalType: rect.set(satRect); break;
						case coronalType: rect.set('fill', this.state.verticalPattern); break;
						case axialType: rect.set('fill', this.state.verticalPattern); break;
						default: console.log('default 1'); return;
					}
				} else if (this.props.scanner.localSerie.plane === coronalType) {
					switch (isMultipane) {
						case sagittalType: rect.set('fill', this.state.verticalPattern); break;
						case coronalType: rect.set(satRect); break;
						case axialType: rect.set('fill', this.state.horizontalPattern); break;
						default: console.log('default 2'); return;
					}
				} else if (this.props.scanner.localSerie.plane === axialType) {
					switch (isMultipane) {
						case sagittalType: rect.set('fill', this.state.horizontalPattern); break;
						case coronalType: rect.set('fill', this.state.horizontalPattern); break;
						case axialType: rect.set(satRect); break;
						default: console.log('default 3'); return;
					}
				}
				canvasEx.add(rect)
				this.props.planSlicesAction.canvasUpdateGraphicrx(item.canvasId, canvasEx)
			}
		})
	}

	openRx = () => {
		this.setState({
			imagesSettingsModal: {
				...this.state.imagesSettingsModal,
				isVisible: true,
				mode: 'CopyRx',
				title: 'GraphicRx Panel'
			}
		})
		this.props.planSlicesAction.enableSat(false);
		this.props.scannerAction.changeStore('modalIsOpen', true);
		this.addRxToViewports()
	}

	renderGraphicRx = images => {
		const {
			scan: { isRunning, cancelled },
		} = this.props.scanner;
		return (
			<Grid.Column>
				<Button disabled={!images.length || isRunning || cancelled} onClick={this.openRx}>
					{(images.length && !isRunning && !cancelled)
						? <img src={rxImg} alt={rxImg} />
						: ''}
				</Button>
				<div className="button-caption">Graphic RX</div>
			</Grid.Column>
		)
	}

	openSat = () => {
		this.setState({
			imagesSettingsModal: {
				...this.state.imagesSettingsModal,
				isVisible: true,
				mode: 'SAT',
				title: 'Sat Pulse Panel'
			}
		});
		this.props.planSlicesAction.enableSat(true);
		this.props.scannerAction.changeStore('modalIsOpen', true);
	}

	renderSat = images => {
		const {
			scan: { isRunning, cancelled },
		} = this.props.scanner;
		return (
			<Grid.Column>
				<Button disabled={!images.length || isRunning || cancelled} onClick={this.openSat}>
					{(images.length && !isRunning && !cancelled)
						? <img src={satImg} alt={satImg} />
						: ''}
				</Button>
				<div className="button-caption">SAT</div>
			</Grid.Column>
		)
	}

	render() {
		const { files: { images } } = this.props.scanner;
		return (
			<div className="add-params">
				{this.renderImagesSettingsModalModal()}
				<Form size="mini">
					<Header
						as="h4"
						content="Additional Parameters"
						textAlign="center"
					/>
					<Grid>
						<Grid.Row width={8}>
							{this.renderSat(images)}
							{this.renderGraphicRx(images)}
							<ReconstructionNext />
						</Grid.Row>
						<Grid.Row width={8}>
							<Grid.Column width={4}>
								<Button disabled />
							</Grid.Column>
							<Grid.Column width={4}>
								<Button disabled />
							</Grid.Column>
							<Grid.Column width={4}>
								<Button disabled />
							</Grid.Column>
							<Grid.Column width={4}>
								<Button disabled />
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Form>
			</div>
		);
	}
}

const mapStateToProps = ({ scanner, planSlices, coords }) => ({ scanner, planSlices, coords });
const mapDispatchToProps = dispatch => ({
	scannerAction: bindActionCreators({ changeLocalSerieField, changeStore, syncSeries }, dispatch),
	planSlicesAction: bindActionCreators({ enablePlanslice, canvasUpdateGraphicrx, enableSat, checkModal }, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AdditionalParameters);
