import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { connect } from "react-redux";
import { Header, Table } from "semantic-ui-react";
import { Api } from '../../services/api';
import { signOut, endExam, selectActiveSerie, examStatus, newExam, resetPlanslices } from '../../actions';
import Sound from 'react-sound';
import './styles.css';



class RxManager extends Component {
	state = {
		series: [],
	}

	signOut = () => {
		Api.auth.logout(this.props.user.userData.customerId, this.props.user.userData.id, this.props.user)
		localStorage.removeItem('logUserId');
		this.props.userAction.signOut();
		this.props.scannerAction.endExam();
		this.props.scannerAction.newExam();
		this.props.history.push('/login');
	};

	endExam = () => {
		this.props.scannerAction.endExam();
		this.props.scannerAction.resetPlanslices();
		this.props.scannerAction.examStatus(true, false);
		// this.props.scannerAction.newExam();
	};

	async handleClickSetSerie(serie) {
		this.props.scannerAction.selectActiveSerie(serie);
		this.props.scannerAction.newExam()
	}

	renderEmptyTable = () => {
		const array = [1, 2, 3, 4, 5, 6, 7, 8, 9]
		return (
			<div className='table-body table-body-empty'>
				<div className='table-header'>
					<span className='header-number'>#</span>
					<span className='header-title'>Series Description</span>
				</div>
				<Table celled className='rx-manager-series-table'>
					<Table.Body>
						{array.map((item, index) => {
							return (
								<Table.Row
									key={index}
								>
									<Table.Cell className='table-number'></Table.Cell>
									<Table.Cell></Table.Cell>
								</Table.Row>
							)
						})}
					</Table.Body>
				</Table>
			</div>
		)
	}

	renderEmptyRows = () => {
		let dif = 9 - this.props.scanner.series.length;
		let array = [];
		for (let i = 1; i <= dif; i++) {
			array.push(i);
		}
		return array;
	}

	renderSerieTable = () => {
		const emptyRows = this.renderEmptyRows();
		const el = document.querySelector('.table-body')
		el.scrollTop = 0
		const tableStyle = this.props.scanner.series.length < 9 ? 'hidden' : 'auto';
		return (
			<div className='table-body' style={{ overflowY: tableStyle }}>
				<div className='table-header'>
					<span className='header-number'>#</span>
					<span className='header-title'>Series Description</span>
				</div>
				<Table celled className='rx-manager-series-table'>
					<Table.Body className='table-serie-body'>
						{this.props.scanner.series.map((item, index) => {
							return (
								<Table.Row
									onClick={() => this.handleClickSetSerie(item)}
									key={index}
									className={this.props.scanner.activeSerie.id === item.id ? 'active' : ''}
								>
									<Table.Cell className='table-number'>{item.serialNumber}</Table.Cell>
									<Table.Cell>{item.seriesDesc}</Table.Cell>
								</Table.Row>
							)
						})}
						{emptyRows.map((item, index) => {
							return <Table.Row
								key={index}
							>
								<Table.Cell className='table-number'></Table.Cell>
								<Table.Cell></Table.Cell>
							</Table.Row>
						})}
					</Table.Body>
				</Table>
			</div>
		)
	}

	render() {
		return (
			<div className="rx-manager">
				<Header as="h4" content="Rx Manager" textAlign="center" />
				<div className='buttons'>
					<div>
						<button onClick={this.signOut}>
							Logout
						</button>
					</div>
					<div>
						<button onClick={this.endExam} className='end-exam-button'>
							End Exam
						</button>
					</div>
				</div>
				{this.props.scanner.series[0] !== undefined ? this.renderSerieTable() : this.renderEmptyTable()}
			</div>
		);
	}
}

const mapStateToProps = ({ scanner, user }) => ({ scanner, user });
const mapDispatchToProps = dispatch => ({
	userAction: bindActionCreators({ signOut }, dispatch),
	scannerAction: bindActionCreators({ endExam, selectActiveSerie, examStatus, newExam, resetPlanslices }, dispatch)
});

export default compose(
	withRouter,
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(RxManager);