import { createActions } from 'redux-actions';

const SAVE_TOOLTIPS = 'SAVE_TOOLTIPS';
const CHANGE_TOOLTIPS_STORE = 'CHANGE_TOOLTIPS_STORE'

const {
  saveTooltips,
  changeTooltipsStore,
} = createActions({
  [SAVE_TOOLTIPS]: tooltips => tooltips,
  [CHANGE_TOOLTIPS_STORE]: (name, value) => ({name, value}),
});

export {
    saveTooltips,
    SAVE_TOOLTIPS,
    changeTooltipsStore,
    CHANGE_TOOLTIPS_STORE
};
