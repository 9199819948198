import React from 'react'
import { Modal } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { changeLocalSerieField, changeStore } from '../../actions';
import Tooltip from '../tooltip/Tooltip';

class ImagingModal extends React.Component {
    state = {
        imagingOptions: [
            {
                id: '1',
                name: 'cardiacGating',
                selected: false,
                label: 'Cardiac Gating',
                abbr: 'CG',
            }, {
                id: '2',
                name: 'peripherelGating',
                selected: false,
                label: 'Peripherel Gating',
                abbr: 'PG',
            }, {
                id: '3',
                name: 'fatSat',
                selected: false,
                label: 'Fat Sat',
                abbr: 'FSAT',
            }, {
                id: '4',
                name: 'waterSat',
                selected: false,
                label: 'Water Sat',
                abbr: 'WSAT',
            }, {
                id: '5',
                name: 'noPhaseWrap',
                selected: false,
                label: 'No Phase Wrap',
                abbr: 'NPR',
            }, {
                id: '6',
                name: 'ractangularFov',
                selected: false,
                label: 'Ractangular FOV',
                abbr: 'RFOV',
            }, {
                id: '7',
                name: 'tailoredRf',
                selected: false,
                label: 'Tailored RF',
                abbr: 'TRF',
            }, {
                id: '8',
                name: 'flowComp',
                selected: false,
                label: 'Flow Comp',
                abbr: 'FC',
            }, {
                id: '9',
                name: 'respiratoryComp',
                selected: false,
                label: 'Respiratory Comp',
                abbr: 'RC',
            }, {
                id: '10',
                name: 'extendedDymamicRange',
                selected: false,
                label: 'Extended Dymamic Range',
                abbr: 'EDR',
            }, {
                id: '11',
                name: 'squarePixel',
                selected: false,
                label: 'Square Pixel',
                abbr: 'SQP',
            }
        ],
    }

    componentWillReceiveProps() {

        let string = this.props.setInputFields;
        let array = string.split(', ');
        let stateArr = this.state.imagingOptions;
        stateArr.forEach(value => {
            value.selected = false;
        })

        array.forEach(value => {
            let i = stateArr.findIndex(element => element.abbr === value);
            if (i !== -1) stateArr[i].selected = true;
        })

        this.setState({ imagingOptions: stateArr })

    }


    onClose = () => {
        this.props.onClose();
    }

    handleChangeImageOption = (item) => {
        const { imagingOptions } = this.state;
        for (let i = 0; i < imagingOptions.length; i++) {
            if (item.name === imagingOptions[i].name) {
                imagingOptions[i].selected = !imagingOptions[i].selected;
                break;
            }
        }

        this.setState({
            imagingOptions: [
                ...imagingOptions
            ],
        });
    }

    handleChangeAllImageOptions = () => {
        const { imagingOptions } = this.state;
        const allSelected = imagingOptions.every((item) => item.selected);

        imagingOptions.forEach((item) => {
            item.selected = !allSelected;
        })

        this.setState({
            imagingOptions: [
                ...imagingOptions
            ]
        });
    }

    getFields = () => {
        const { imagingOptions } = this.state;

        const localimagingOptions = [...imagingOptions];
        let string = localimagingOptions.filter(item => item.selected).map(item => item.abbr).join(', ');
        let number = localimagingOptions.filter(item => item.selected).map(item => item.id)

        this.props.getInputFields(string);

        // this.props.scannerAction.changeLocalSerieField('imgOptions', number)

        this.props.onClose();
    }


    render() {

        const imagingOptions = this.state.imagingOptions

        return (
            <Modal open={this.props.modalOpen} onClose={this.onClose} size="mini" closeIcon className='imaging-options-modal' closeOnDimmerClick={false}>
                <Modal.Header className="grey">Imaging Options Panel</Modal.Header>
                <Modal.Content>
                    <ul className='imaging-options-list'>
                        {imagingOptions.map((item, index) => (
                            <li key={index}>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={item.selected}
                                        value={item.name}
                                        onChange={() => this.handleChangeImageOption(item)}
                                    />
                                    <Tooltip
                                        trigger={<span>{item.label}</span>}
                                        key={index}
                                        paramKey={item.name}
                                    />
                                </label>
                            </li>
                        ))}
                    </ul>
                    <hr />
                    <label className='imaging-select-all'>
                        <input
                            type="checkbox"
                            checked={imagingOptions.every((item) => item.selected)}
                            onChange={this.handleChangeAllImageOptions}
                        />
                        <span>Select All</span>
                    </label>
                    <div className='imaging-options-modal-buttons'>
                        <button onClick={this.onClose}>Cancel</button>
                        <button onClick={() => this.getFields()}>Save</button>
                    </div>
                </Modal.Content>
            </Modal>
        )
    }
}

const mapStateToProps = ({ scanner }) => ({ scanner });
const mapDispatchToProps = dispatch => ({
    scannerAction: bindActionCreators({ changeLocalSerieField, changeStore }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ImagingModal)