import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';
import userReducer from './userReducer';
import loadingReducer from './loadingReducer';
import scannerReducer from './scannerReducer';
import planSlicesReducer from './planSlicesReducer';
import tooltipReducer from './tooltipReducer';
import coilsReducer from './coilsReducer';
import coordsReducer from './coordsReducer';


export default combineReducers({
  toastr: toastrReducer,
  user: userReducer,
  form: formReducer,
  scanner: scannerReducer,
  loading: loadingReducer,
  planSlices: planSlicesReducer,
  tooltips: tooltipReducer,
  coils: coilsReducer,
  coords: coordsReducer,
});