import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Image, Header, Button, Modal } from 'semantic-ui-react';
import { storeSeries, toggleProtocolPlace, changeStore } from '../../actions';
import { Api } from '../../services/api';
import ProtocolsModal from './ProtocolsModal';
import humanBodyImage from '../../images/human-body.png';
import './style.css';

const SCANNABLE_TYPE = 'Scannable';
const MIP_TYPE = 'Reconstruction'

class PatientProtocols extends Component {
  state = {
    modalOpen: false,
    protocols: [],
    showModal: false,
    showErrorModal: false,
  };

  onBodyPartClick = async bodyPart => {
    const protocols = await Api.protocol.getByBodyPart(bodyPart);
    if (protocols.data && protocols.data.length)
      this.setState({
        modalOpen: true,
        protocols: protocols.data
      });
    else
      this.setState({ showModal: true })
  };

  onClose = () => {
    this.setState({ showModal: false, showErrorModal: false })
  }

  handleClose = () => {
    this.setState({ modalOpen: false });
  };

  onProtocolClick = async protocol => {
    this.setState({ modalOpen: false });
    const series = await Api.protocol.getSeries(protocol.id);
    const seriesMIP = await Api.protocol.getMipSeries(protocol.id);
    const { data } = series;
    const dataMIP = seriesMIP.data;
    const preparedSequences = [];
    const mipSequences = [];

    const checkPlane = (plane) => {
      switch (plane) {
        case '1': return 'Coronal';
        case '2': return 'Sagittal';
        case "3": return 'Axial';
        case '4': return 'Multiplane';
        default: return plane;
      }
    }

    data.map((el) => {
      if (el.type === SCANNABLE_TYPE) {
        el.plane = checkPlane(el.plane)
        preparedSequences.push(el);
      }
    })
    if (dataMIP !== undefined) {
      dataMIP.map((el) => {
        if (el.type === MIP_TYPE) {
          el.plane = checkPlane(el.plane)
          mipSequences.push(el);
        }
      })
    }

    if (preparedSequences && preparedSequences.length)
      this.props.scannerAction.storeSeries(protocol, preparedSequences, mipSequences, false);
    else {
      this.setState({ showErrorModal: true })
    }

    this.props.scannerAction.changeStore('patientInfoGuide', 'none')
    this.props.scannerAction.changeStore('patientInfoFullGuide', 'none')
    this.props.scannerAction.changeStore('saveSeriesGuide', 'block')

  };

  render() {
    const { isRunning } = this.props.scanner.scan;

    return (
      <div className='human-body-parts'>
        <Header
          as="h4"
          textAlign="center"
          content="Patient Protocol"
        />
        <Image src={humanBodyImage} className="human-body-image" />
        <Button
          size="mini"
          name="head"
          color="teal"
          disabled={isRunning}
          className="head-button"
          onClick={() => this.onBodyPartClick('head')}
        >
          Head/Brain
        </Button>
        <Button
          size="mini"
          name="neck"
          color="teal"
          disabled={isRunning}
          className="neck-button"
          onClick={() => this.onBodyPartClick(['neck', 'spine'])}
        >
          Neck/Cervical
        </Button>
        <Button
          size="mini"
          name="chest"
          color="teal"
          disabled={isRunning}
          className="chest-button"
          onClick={() => this.onBodyPartClick('chest')}
        >
          Chest/Thoracic
        </Button>
        <Button
          size="mini"
          color="teal"
          disabled={isRunning}
          name="upper-extremity"
          className="upper-extremity-button"
          onClick={() => this.onBodyPartClick('upper-extremity')}
        >
          Upper Extremity
        </Button>
        <Button
          size="mini"
          color="teal"
          name="abdomen"
          disabled={isRunning}
          className="abdomen-button"
          onClick={() => this.onBodyPartClick('abdomen')}
        >
          Abdomen/Lumbar
        </Button>
        <Button
          size="mini"
          color="teal"
          name="pelvis"
          disabled={isRunning}
          className="pelvis-button"
          onClick={() => this.onBodyPartClick('pelvis')}
        >
          Pelvis
        </Button>
        <Button
          size="mini"
          color="teal"
          disabled={isRunning}
          name="lower-extremity"
          className="lower-extremity-button"
          onClick={() => this.onBodyPartClick('lower-extremity')}
        >
          Lower Extremity
        </Button>

        <Button
          size="mini"
          color="teal"
          disabled={isRunning}
          id="back-to-position-button"
          onClick={() => this.props.scannerAction.toggleProtocolPlace(false)}
        >
          Position
        </Button>

        <ProtocolsModal
          onClose={this.handleClose}
          modalOpen={this.state.modalOpen}
          protocols={this.state.protocols}
          onProtocolClick={this.onProtocolClick}
        />

        <Modal
          open={this.state.showModal}
          className='body-part-confirm-message'>
          <Modal.Content>
            <p>No protocols for the selected part of body.</p>
            <button onClick={this.onClose}>OK</button>
          </Modal.Content>
        </Modal>

        <Modal
          open={this.state.showErrorModal}
          className='protocol-error-message'>
          <Modal.Content>
            <p>No sequences for the selected protocol.</p>
            <button onClick={this.onClose}>OK</button>
          </Modal.Content>
        </Modal>

      </div>
    );
  }
}

const mapStateToProps = ({ scanner }) => ({ scanner });
const mapDispatchToProps = dispatch => ({
  scannerAction: bindActionCreators({ storeSeries, toggleProtocolPlace, changeStore }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientProtocols);
