import { handleActions } from 'redux-actions';
import { LOGIN_USER, STORE_USER, SIGN_OUT, SET_ACTIVE_LOG_USER } from '../actions';

const defaultState = {
  userData: null,
  activeLogId: 0
};

export default handleActions(
  {
    [LOGIN_USER]: (state, { payload: { userData } }) => {
      // save token in browser storage
      window.localStorage.setItem('authToken', userData.token);

      return {
        ...state,
        userData: userData.user
      };
    },
    [STORE_USER]: (state, { payload: { userData } }) => {
      return {
        ...state,
        userData: userData
      };
    },
    [SET_ACTIVE_LOG_USER]: (state, { payload: { id } }) => {
      return {
        ...state,
        activeLogId: id
      }
    },
    [SIGN_OUT]: state => {
      // remove token from browser storage
      window.localStorage.removeItem('authToken');

      return {
        ...state,
        userData: null
      };
    }
  },
  defaultState
);
