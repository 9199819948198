import React from 'react'
import { Image } from "semantic-ui-react";
import { changeLocalSerieField, enablePlanslice, canvasUpdateGraphicrx, enableSat, checkModal, changeStore, setActiveViewport, cancelScan } from "../../actions";
import { bindActionCreators } from "redux";
import { CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import { connect } from "react-redux";
import PlanSlicesImage from '../planSlices/planSlicesImage'
import './viewportWindow.css'
import testImg from '../../images/test-img.png'

class ViewportWindow extends React.Component {
    state = {
        localSerieId: {
            id: [],
            images: [],
            plane: [],
            imagePlanes: [],
        },
        imageStep: 0,
        newActiveSequence: 0,
    };
    //////////////////////////////////////////
    componentDidMount() {
        this.setState({ localSerieId: this.props.localSerieId })
        // this.setPlane()
    }
    //////////////////////////////////////////
    onMouseDown = e => {
        const activeSequence = this.props.activeSequence;
        let images = this.state.localSerieId.images[activeSequence];
        if (images === undefined) images = []
        this.props.totalSlides(this.props.canvasID, images.length)

        let viewports = document.querySelectorAll('.plan-slices-dashboard')
        let i;
        for (i = 0; i < viewports.length; i++) {
            viewports[i].style.outline = 'none'
        }
        let viewports2 = document.querySelectorAll('canvas')
        let t;
        for (t = 0; t < viewports2.length; t++) {
            viewports2[t].style.outline = 'none'
        }
        this.props.planSlicesAction.setActiveViewport(this.props.canvasID)
        e.target.style.outline = '2px solid red'
    }

    onKeyPress = e => {
        if (e.nativeEvent.code === 'Delete' || e.nativeEvent.code === 'Backspace' || e.nativeEvent.keyCode === 46 || e.nativeEvent.keyCode === 8) {
            let { canvas } = this.props.planSlices
            let canvasEx = canvas[this.props.canvasID]
            let activeObject = canvasEx.getActiveObject()
            canvasEx.remove(activeObject)
            this.props.planSlicesAction.canvasUpdateGraphicrx(this.props.canvasID, canvasEx)
        }
    }

    renderEmptyImage = () => {
        return (
            <div className="empty-image">
                <img style={{ transform: `scale(${1 + (this.props.zoom / 100)})`, transformOrigin: 'center' }} src="https://static.independent.co.uk/s3fs-public/thumbnails/image/2017/09/12/11/naturo-monkey-selfie.jpg?w968h681" alt="" />
            </div>
        );
    };

    renderSeriesImage = () => {
        const activeSequence = this.props.activeSequence;
        const currentSlide = this.props.currentSlide;
        const canvas = this.props.canvasID
        if (activeSequence === null) {
            return (
                <div className="empty-image">
                    <div className='plan-slices-dashboard'></div>
                    <img style={{ opacity: 0 }} src={testImg} alt="" />
                </div>
            )
        }
        const images = this.state.localSerieId.images[activeSequence];
        if (images !== undefined) {

            return (
                <React.Fragment>
                    <PlanSlicesImage
                        canvasID={canvas}
                        localSerieId={this.props.localSerieId}
                        activeSequence={activeSequence}
                        currentSlide={currentSlide}
                        getCoords={this.props.getCoords}
                    />
                    <CarouselProvider
                        currentSlide={this.props.currentSlide}
                        dragEnabled={false}
                        touchEnabled={false}
                        naturalSlideWidth={50}
                        naturalSlideHeight={50}
                        totalSlides={images.length}
                    >
                        <div className='plan-slices-slider-container'>
                            <Slider classNameAnimation='null' style={{ transform: `scale(${1 + (this.props.zoom / 100)})`, transformOrigin: 'center' }}>
                                {images.map((image, index) => (
                                    <Slide index={image.id} key={index} >
                                        <Image
                                            src={image}
                                            draggable={false}
                                            onError={() => { console.log('Error: Image fails to load'); this.props.scannerAction.cancelScan() }}
                                        />
                                        <div className='rx-slide-white-overlay' />
                                    </Slide>
                                ))}
                            </Slider>
                        </div>
                    </CarouselProvider>
                </React.Fragment>
            );
        }
    };

    render() {
        return (
            <div className='plan-slices-modal-content-block'
                onMouseDown={e => this.onMouseDown(e)}
                onTouchStart={e => this.onMouseDown(e)}
                onKeyUp={e => this.onKeyPress(e)}
                tabIndex='0'
            >
                {this.renderSeriesImage()}
            </div>
        )
    }
}

const mapStateToProps = ({ scanner, planSlices }) => ({ scanner, planSlices });
const mapDispatchToProps = dispatch => ({
    scannerAction: bindActionCreators({ changeLocalSerieField, changeStore, cancelScan }, dispatch),
    planSlicesAction: bindActionCreators({ enablePlanslice, canvasUpdateGraphicrx, enableSat, checkModal, setActiveViewport }, dispatch)
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewportWindow);