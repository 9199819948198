import React from 'react';
import ReactDOM from 'react-dom';
import ReduxToastr from 'react-redux-toastr';
import { Provider } from 'react-redux';

import App from './app/App';
import ErrorWrapper from './app/components/errorWrapper/ErrorWrapper';
import configureStore from './store';
import * as serviceWorker from './serviceWorker';

const store = configureStore()
window.soundManager.setup({ debugMode: false, ignoreMobileRestrictions: true });
ReactDOM.render(
  <Provider store={store}>
    <ErrorWrapper>
      <App />
      <ReduxToastr />
    </ErrorWrapper>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();

// export store for usage from non-react component
export { store };