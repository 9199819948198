import { handleActions } from 'redux-actions';
import { SAVE_COILS } from '../actions';

const defaultState = {
    data: {},
}

export default handleActions(
    {
        [SAVE_COILS]: (state, {payload: coils}) => {
            let data = {};
            data = {
                ...data,
                coils
            }

            return {
                ...state,
                data
            };
        },    
    }, 
    defaultState
)