import BaseApiService from './base';

export class TooltipApiService extends BaseApiService {
    // eslint-disable-next-line
    constructor() {
        super();
    }

    getAll() {
        return this.request({
          method: 'get',
          url: '/api-v2/tooltip/mri',
        });
    }
    getAllOptions() {
      return this.request({
        method: 'get',
        url: '/api-v2/options-tooltip',
      });
  }
}