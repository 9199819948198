import React from 'react'
import './Restore.css'
import { Message, Input, Button } from 'semantic-ui-react';
import { Api } from '../../services/api';

export default class Restore extends React.Component {

    state = {
        emailValue: '',
        messageText: '',
        messageType: '',
        messageHidden: true,
        messageState: {
            negative: false,
            positive: true
        }
    }

    handleChange = (event) => {
        this.setState({emailValue: event.target.value, messageHidden: true});
    }

    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    handleSubmit = async () => {
        if (this.validateEmail(this.state.emailValue)) {
            const response = await Api.auth.restore({email: this.state.emailValue});
            if (response.status !== 200) {
                this.setState({ 
                    messageHidden: false,
                    messageType: 'Error',
                    messageText: 'The system encountered some problems accessing your account, please contact your administrator.',
                    messageState: {negative: true, positive: false},
                }) 
            } else this.setState({ 
                messageHidden: false,
                messageType: 'Succes',
                messageText: 'Verification link sent to your email! Please, check your inbox!',
                messageState: {negative: false, positive: true},
            })

        } else this.setState({ 
            messageHidden: false,
            messageType: 'Error',
            messageText: 'Invalid Email',
            messageState: {negative: true, positive: false},
        })
    }

    render () {
        return (
            <React.Fragment>
                <div className="restore-page">
                    <div className='restore-block'> 
                        <h3>Enter your email</h3>
                        <Input type='email'  placeholder='Your email' className='input-restore'>
                            <input onChange={this.handleChange.bind(this)}/>
                        </Input>
                        <p>{this.state.error}</p>
                        <Button color="teal" className='restore-submit' onClick={() => this.handleSubmit()}>
                            Submit
                        </Button>
                    </div>
                    <Message
                        size='big'
                        hidden={this.state.messageHidden}
                        {...this.state.messageState}>
                        <Message.Header>{this.state.messageType}</Message.Header>
                        {this.state.messageText}
                    </Message>
                </div>    
            </React.Fragment>
        )
    }
}