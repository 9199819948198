import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { changeLocalSerieField, changeStore } from '../../actions';
import { Form, Header } from 'semantic-ui-react';
import SelectedProtocol from '../patientProtocols/SelectedProtocol';
import ImagingModal from './ImagingModal';
import Tooltip from '../tooltip/Tooltip';

const imgOpt = {
    imagingOptions: [
        {
            id: '1',
            abbr: 'CG',
        }, {
            id: '2',
            abbr: 'PG',
        }, {
            id: '3',
            abbr: 'FSAT',
        }, {
            id: '4',
            abbr: 'WSAT',
        }, {
            id: '5',
            abbr: 'NPR',
        }, {
            id: '6',
            abbr: 'RFOV',
        }, {
            id: '7',
            abbr: 'TRF',
        }, {
            id: '8',
            abbr: 'FC',
        }, {
            id: '9',
            abbr: 'RC',
        }, {
            id: '10',
            abbr: 'EDR',
        }, {
            id: '11',
            abbr: 'SQP',
        }
    ],
}

class ImagingParametrs extends Component {

    state = {
        modalOpen: false,
        wasSaved: false,
        inputFields: '',
        imagingPlane: '',
        imagingMode: '',
        pulseSequence: '',
        imgOptions: [],
    };

    onClose = () => {

        this.setState({
            modalOpen: !this.state.modalOpen,
        });
    }

    handleCancel = (value) => {
        this.setState({ wasSaved: value, modalOpen: !this.state.modalOpen })
    }


    getInputFields = (string) => {
        this.props.scannerAction.changeStore('fatWaterField', string)
    }


    renderImagingOptionModal = () => {

        return (
            <ImagingModal
                modalOpen={this.state.modalOpen}
                onClose={this.onClose}
                getInputFields={this.getInputFields}
                setInputFields={this.props.scanner.fatWaterField}
            />
        )
    }

    componentDidUpdate(prevProps) {
        const { localSerie } = this.props.scanner;
        if ((prevProps.scanner.examEnded !== this.props.scanner.examEnded) || (prevProps.scanner.isCoil !== this.props.scanner.isCoil)) {
            this.setState({ inputFields: '' })
        }
        // set series name from active series
        if (prevProps.scanner.localSerie !== this.props.scanner.localSerie) {
            if (prevProps.scanner.localSerie.imgOptions !== this.props.scanner.localSerie.imgOptions) {
                this.setState({
                    imagingPlane: localSerie.plane || '',
                    imagingMode: localSerie.imagingMode || '',
                    pulseSequence: localSerie.pulseSequence || '',
                    imgOptions: localSerie.imgOptions
                }, () => {
                    if (localSerie.imgOptions !== undefined && localSerie.imgOptions !== '' && localSerie.imgOptions !== null) {
                        let array = this.state.imgOptions;
                        let strArray = [];
                        array.forEach(id => {
                            let findEl = imgOpt.imagingOptions.find(element => element.id === id)
                            if (findEl !== undefined) strArray.push(findEl.abbr)
                        })
                        this.props.scannerAction.changeStore('fatWaterField', strArray.join(', '))
                    }
                });
            } else {
                this.setState({
                    imagingPlane: localSerie.plane || '',
                    imagingMode: localSerie.imagingMode || '',
                    pulseSequence: localSerie.pulseSequence || '',
                });
            }
        }
    }

    render() {
        // const string = this.state.inputFields;
        const string = this.props.scanner.fatWaterField;
        return (
            <React.Fragment>
                {this.renderImagingOptionModal()}
                <Form size="mini">
                    <Header
                        as="h4"
                        content="Imaging Parameters"
                        textAlign="center"
                    />
                    <div className='imaging-plane-mode-group'>
                        <div className='plane-field'>
                            <span>Plane</span>
                            <input type="text" readOnly defaultValue={this.state.imagingPlane} />
                        </div>
                        <div className='mode-field'>
                            <span>Mode</span>
                            <input type="text" readOnly defaultValue={this.state.imagingMode} />
                        </div>
                    </div>
                    <Form.Input
                        name="pulseSeq"
                        label="Pulse seq"
                        value={this.state.pulseSequence}
                        className='pulse-field'
                    />
                    <Form.Group className='imaging-options-group'>
                        <Form.TextArea
                            name="imagingOptions"
                            label="Imaging Options"
                            value={string}
                            readOnly
                            className='imaging-options-field'
                        />
                        <Tooltip paramKey={'imagingOptions'} trigger={<button className='imaging-options-button' onClick={this.onClose}>.</button>} />
                    </Form.Group>
                </Form>
                <SelectedProtocol />

            </React.Fragment>
        )
    }
}

const mapStateToProps = ({ scanner }) => ({ scanner });
const mapDispatchToProps = dispatch => ({
    scannerAction: bindActionCreators({ changeLocalSerieField, changeStore }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ImagingParametrs)