import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Header, Select } from 'semantic-ui-react';
import PatientCoils from '../patientCoils/PatientCoils';
import { changeLocalSerieField, examStatus, newExam, changeStore } from '../../actions';
import ImagingParametrs from './ImagingParametrs';
import './style.css'

class PatientPosition extends Component {

	state = {
		serieDesc: '',
		patientPosition: 'supine',
		patientOrientation: 'head',
	};

	patientEntries = [
		{ text: 'Head First', value: 'head', id: 1 },
		{ text: 'Feet First', value: 'feet', id: 2 },
	];

	patientPositions = [
		{ text: 'Supine', value: 'supine', id: 1 },
		{ text: 'Prone', value: 'prone', id: 2 },
	];

	handleChange = (e, { name, value }) => {
		this.setState({ [name]: value }, () => {
			if (e) {
				this.setPositionImage();
			}
		});
	};

	setPositionImage() {
		const { patientPosition, patientOrientation } = this.state;
		if (patientPosition !== '' && patientOrientation !== '') {
			let patientPositionNew = this.patientPositions.find(el => el.value === patientPosition);
			let patientEntryNew = this.patientEntries.find(el => el.value === patientOrientation);
			this.props.scannerAction.changeStore('patientPosition', patientPositionNew.value)
			this.props.scannerAction.changeStore('patientEntry', patientEntryNew.value)
		}
	};

	handleClose = () => {
		this.setState({ modalOpen: false });
	};

	componentDidUpdate(prevProps) {

		const { localSerie } = this.props.scanner;
		if (prevProps.scanner.activeSerie !== this.props.scanner.activeSerie) {
			this.setState({
				serieDesc: localSerie.seriesDesc || '',
			})
			if (this.state.patientPosition === '') {
				this.props.scannerAction.changeStore('patientPosition', 'supine')
				this.setState({ patientPosition: 'supine' })
			}
			if (this.state.patientOrientation === '') {
				this.props.scannerAction.changeStore('patientEntry', 'head')
				this.setState({ patientOrientation: 'head' })
			}
		}

		if (prevProps.scanner.examEnded !== this.props.scanner.examEnded || prevProps.scanner.isCoil !== this.props.scanner.isCoil) {
			if (this.props.scanner.examEnded || this.props.scanner.isCoil) {
				this.setState({
					patientPosition: '',
					patientOrientation: '',
				})
			}
		}

	}
	render() {
		return (
			<React.Fragment>
				<Form size="mini">
					<Header
						as="h4"
						content="Patient Position"
						textAlign="center"
					/>
					<Form.Field
						control={Select}
						name="patientPosition"
						label='Patient Position'
						options={this.patientPositions}
						onChange={this.handleChange}
						value={this.state.patientPosition}
						className='patient-selection-field'
					/>

					<Form.Field
						control={Select}
						name="patientOrientation"
						label='Patient Entry'
						options={this.patientEntries}
						onChange={this.handleChange}
						value={this.state.patientOrientation}
						className='patient-selection-field'
					/>

					<PatientCoils />
					<Form.Input
						name="serieDescription"
						label="Series Description"
						readOnly
						className='series-description'
						value={this.state.serieDesc}
					/>
				</Form>
				<ImagingParametrs />

			</React.Fragment>
		)
	}
}

const mapStateToProps = ({ scanner }) => ({ scanner });
const mapDispatchToProps = dispatch => ({
	scannerAction: bindActionCreators({ changeLocalSerieField, examStatus, newExam, changeStore }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientPosition)