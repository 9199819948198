import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Grid } from 'semantic-ui-react';
import PatientPosition from '../patientPosition/PatientPosition';
import PatientProtocols from '../patientProtocols/PatientProtocols';
import PatientInformation from '../patientInformation/PatientInformation';
import ScaledImage from '../scaledImage/ScaledImage'
import ScanTiming from '../ScanTiming';
import NavigationSection from '../navigationSection/NavigationSection';
import AutoView from '../autoView/AutoView';
import AdditionalParameters from '../additionalParameters';
import RxManager from '../RxManager';
import ScannerParameters from '../scannerParameters/ScannerParameters';
import AcquisitionTiming from '../acquisitionTiming';
import { Api } from '../../services/api';
import ScaningRage from '../scaningRage';
import Guide from '../guide/Guide';
import { toastr } from 'react-redux-toastr';
import { changeEnabledStartScan, saveTooltips, changeStore, saveCoils, setActiveLogUser, storeSeriesFiles } from '../../actions';

import './style.css';

const defaultWidth = 948;
const defaultHeight = 621;

class Home extends Component {

  state = {
    isProtocolPlace: false,
    transform: 1,
    showAutoview: false,
  }

  getAllTooltips = async () => {
    const responce = await Api.tooltip.getAll();
    const responce2 = await Api.tooltip.getAllOptions();
    const responce3 = await Api.coils.getAllCoils();
    if (responce.status === 200) {
      let data = [];
      let resp1 = responce.data;
      let resp2 = responce2.data;
      data = resp1.concat(resp2);
      this.props.tooltipAction.saveTooltips(data);
      this.props.coilsAction.saveCoils(responce3.data);
    } else {
      toastr.confirm('Problems appeared while getting tooltips!', {
        disableCancel: true
      });
    }
  }

  floor10 = (value, exp) => {
    return this.decimalAdjust('floor', value, exp);
  };


  decimalAdjust(type, value, exp) {

    if (typeof exp === 'undefined' || +exp === 0) {
      return Math[type](value);
    }
    value = +value;
    exp = +exp;

    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
      return NaN;
    }

    value = value.toString().split('e');
    value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));

    value = value.toString().split('e');
    return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
  }

  scaleApp = () => {
    let width = window.innerWidth;
    let height = window.innerHeight;
    let scale = Math.min(
      width / defaultWidth,
      height / defaultHeight
    )
    const scaleFix = this.floor10(scale, -1)
    this.setState({ transform: scaleFix })
    document.querySelectorAll('.modal').forEach(value => {
      value.style.transform = `scale(${scaleFix})`;
      value.style.transformOrigin = 'center';
    })
  }

  componentDidMount() {
    const logUserId = localStorage.getItem('logUserId');
    if (logUserId) {
      this.props.userAction.setActiveLogUser(logUserId);
    }
    this.scaleApp();
    window.addEventListener('resize', () => this.scaleApp());
    this.getAllTooltips();

    let targetNode = document.querySelector("body");
    let observerOptions = {
      childList: true,
      attributes: false,
      subtree: true
    }

    let observer = new MutationObserver(() => {
      document.querySelectorAll('.modal').forEach(value => {
        value.style.transform = `scale(${this.state.transform})`;
        value.style.WebkitTransform = `scale(${this.state.transform})`;
        value.style.transformOrigin = 'center';
        value.style.WebkitTransformOrigin = 'center';
      })
    });

    observer.observe(targetNode, observerOptions);

  }

  componentWillReceiveProps(nextProps) {
    const {
      openedProtocol,
      showAutoview
    } = nextProps.scanner;

    this.setState({
      isProtocolPlace: openedProtocol,
      showAutoview: showAutoview
    });
  }

  onPrapereToScan = async () => {
    this.props.scannerAction.changeEnabledStartScan(true);
    this.props.scannerAction.changeStore('prepareScanGuide', 'none')
    this.props.scannerAction.changeStore('scanButtonGuide', 'block')

  }

  closeAutoview = () => {
    this.props.scannerAction.changeStore('showAutoview', false)
  }

  render() {
    const styling = {
      transform: `scale(${this.state.transform})`,
      transformOrigin: 'center',
    }
    return (
      <Container className='home-page' style={styling}>
        <Grid stackable>
          <Guide />
          <Grid.Row className='first-grid-row'>
            <Grid.Column className='navigation-section-column'>
              <NavigationSection />
            </Grid.Column>
            <Grid.Column className='patient-information-column'>
              <PatientInformation />
            </Grid.Column>
            <Grid.Column className='patient-position-block-column'>
              <Grid stackable className='patient-position-block'>
                <Grid.Column className='patient-position-column' style={this.state.isProtocolPlace ? { display: 'none' } : {}}>
                  <PatientPosition />
                </Grid.Column>
                <Grid.Column className='patient-position-column' style={!this.state.isProtocolPlace ? { display: 'none' } : {}}>
                  <PatientProtocols />
                </Grid.Column>
                <Grid.Column className='autoview-column'>
                  <AutoView />
                </Grid.Column>
              </Grid>
              <ScaledImage />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row id="remove-height">
            <Grid.Column className='rx-manager-column'>
              <RxManager />
            </Grid.Column>
            <Grid.Column className='scan-timing-column'>
              <ScanTiming />
            </Grid.Column>
            <Grid.Column id="remove-padding">
              <Grid stackable style={{ margin: 0 }}>
                <Grid.Row>
                  <Grid.Column className='additional-parameters-column'>
                    <AdditionalParameters />
                  </Grid.Column>
                  <Grid.Column className='acquisition-timing-column'>
                    <AcquisitionTiming />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row >
                  <Grid.Column className='scaning-rage-block'>
                    <ScaningRage />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column className='prepare-to-scan-column'>
              <button
                onClick={this.onPrapereToScan}
                disabled={!this.props.scanner.prapereToScan}>Prepare to Scan
              </button>
            </Grid.Column>
            <Grid.Column className='bottom-scan-parameters'>
              <ScannerParameters />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = ({ scanner, coils }) => ({ scanner, coils });
const mapDispatchToProps = dispatch => ({
  tooltipAction: bindActionCreators({ saveTooltips }, dispatch),
  scannerAction: bindActionCreators(
    {
      changeEnabledStartScan,
      changeStore,
      storeSeriesFiles
    },
    dispatch
  ),
  coilsAction: bindActionCreators({ saveCoils }, dispatch),
  userAction: bindActionCreators({ setActiveLogUser }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
