import React from 'react';
import { Image, Button } from 'semantic-ui-react';
import { Redirect } from 'react-router';
import logo from '../../images/logo.png';
import navigationImage from '../../images/navigation-image.png';

import './NavigationSection.css'

export default class NavigationSection extends React.Component {

	state = {
		goToAdminPage: false,
	}

	render () {
		if(this.state.goToAdminPage)
		return <Redirect to="/admin-app" />

		return (
			  <div className='navigation-block'>
					<Button onClick={() => this.setState({goToAdminPage: true})}>View administrator panel</Button>
					<Image src={logo} />
					<br />
					<Image src={navigationImage} />
			  </div>
		);
	}
};