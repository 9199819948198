import React from 'react';
import { Modal} from 'semantic-ui-react';


export default ({ modalOpen, onClose, protocols, onProtocolClick }) => {
  return (
    <Modal open={modalOpen} onClose={onClose} size="mini" closeIcon className='protocol-selection-modal'>
        <Modal.Header>Select a protocol</Modal.Header>
        <Modal.Content>
            <div className='protocol-list'>
                <ul>
                    {protocols.length &&
                      protocols.map(protocol => (
                        <li
                          key={protocol.id}
                          onClick={() => onProtocolClick(protocol)}
                          className='protocol-list-item'
                        >
                          {protocol.partOfBody + ': ' + protocol.name}
                        </li>
                      ))}
                </ul>
            </div>           
        </Modal.Content>
    </Modal>
  );
};
