export const defaultState = [
    {
        name: 'te',
        disabled: false,
        label: 'TE',
        min: '',
        max: '',
        value: 0,
        popup: 'TE (Echo Time) controls T2 weighting and affectsSNR. As TE increases t2 weighting increases. However, as TE increases SNR decreases.'
    },
    {
        name: 'tez',
        disabled: true,
        label: 'TE2',
        min: '',
        max: '',
        value: 0,
        popup: ''

    },
    {
        name: 'tr',
        disabled: false,
        label: 'TR',
        min: '',
        max: '',
        value: 0,
        popup: 'TR (Repetition Time) controls t1 weighting and affects SNR and scan time. As TR decreases t1 weighting increases. However, as TR decreases SNR and scan time decreases.'

    },
    {
        name: 'ti',
        disabled: false,
        label: 'TI',
        min: '',
        max: '',
        value: 0,
        popup: 'TI (Inversion Time) controls the amount of time between the 180o inversion pusle and the 90o excitation pusle on an iversion recovery pulse sequence. TI affects image contrast and can be used to suppress the appearance of signal from fat or water on the image.'

    },
    {
        name: 'flipAngle',
        disabled: false,
        label: 'Flip Angle',
        min: '',
        max: '',
        value: 0,
        popup: 'Flip angle controls the degree at which the net magnetic vector is tipped into the transverse plane. Flip angle controls the degree at which the net magnetic vector is tipped into the transverse plane. Flip angle is usually 90° for spin echo pulse sequences and less.'

    },
    {
        name: 'echoTrainFactor',
        disabled: false,
        label: 'Echo Train Length',
        min: '',
        max: '',
        value: 0,
        popup: 'Echo Train Length (ETL) controls the number of times signal is rephrased by the 180° rephrasing pusle in order to create and echo in a fast spin pulse sequence. Each echo is mapped into K-space in order to reduce the time it takes to perform all of the phase.'

    },
    {
        name: 'bandwidth',
        disabled: false,
        label: 'Bandwidth',
        min: '',
        max: '',
        value: 0,
        popup: 'Bandwidth controls the frequency range between the highest and lowest frequencies collected during signal sampling. Bandwidth indirectly affects SNR. As bandwidth increases, SNR decreases and as bandwidth decreases, SNR increases. Decreasing bandwidth.'
    }
]