import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Form, Header, Button } from "semantic-ui-react";
import { changeLocalSerieField } from "../../actions";

import "./styles.css";
import {defaultState, maxFov, minFov} from '../../utils/scaningRageDefaultState'
import Tooltip from "../tooltip/Tooltip";

class ScaningRage extends Component {
	state = {
		scanTimings: [],
		minFov: '',
		maxFov: '',
	}

	handleChange(value, name) {

		this.props.scannerAction.changeLocalSerieField({
			[name]: value
		});
	}

	componentDidMount() {
		this.setState({scanTimings: defaultState, maxFov, minFov})
	}

	componentWillReceiveProps(nextProps) {
		const { scanTimings } = this.state;
		const {examEnded} = nextProps.scanner;
		for (let i = 0; i < scanTimings.length; i++) {
			const element = scanTimings[i];
			if (nextProps.localSerie[element.name] !== null && nextProps.localSerie[element.name] !== undefined) {
				element.value = nextProps.localSerie[element.name];
			} else element.value = 0;
		}

		this.setState({
			scanTimings: [...scanTimings],
			minFov: nextProps.localSerie.minFov,
			maxFov: nextProps.localSerie.maxFov,
		});

		if (examEnded) {
			this.setState({scanTimings: defaultState, maxFov, minFov})
		}

	}

	render() {

		return (
			<div className="scaning-range">
				<Header
					as="h4"
					content="Scanning Range"
					textAlign="center"
				/>
				<div className="scaning-range-table">
					{this.state.scanTimings.map((item, index) => {
						return (
							<div className="scaning-range-table-row" key={index}>
								<div className="scaning-range-table-coll-input-label">{item.label}</div>
								<div className="scaning-range-table-coll-input">
									<Form.Input
										name={item.name}
										value={item.value}
										onChange={e => this.handleChange(e.target.value, item.name)}
										type={(item.name === 'startLocation' || item.name === 'endLocation' || item.name === 'numberSlices') ? 'text' : "number"}
									/>
								</div>
								{item.withButton && <div className="scaning-range-table-coll-button">
									{item.popup ? <Tooltip paramKey={item.name} trigger={<Button color="grey" />} /> : <Button color="grey" />}
								</div>}
								<div className='min-max-fov-field'>{(item.name === 'fov') ? this.state.minFov : ''}</div>
								<div className='min-max-fov-field'>{(item.name === 'fov') ? this.state.maxFov : ''}</div>
							</div>
						)
					})}
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ scanner }) => ({ localSerie: scanner.localSerie, scanner });
const mapDispatchToProps = dispatch => ({
	scannerAction: bindActionCreators({ changeLocalSerieField }, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ScaningRage);
