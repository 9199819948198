import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changeStore, newExam } from '../../actions';
import { Image, Form, Header } from 'semantic-ui-react';
import proneFeet from '../../images/prone-feet.png';
import proneHead from '../../images/prone-head.png';
import supineHead from '../../images/supine-head.png';
import supineFeet from '../../images/supine-feet.png';
import defaultImg from '../../images/empty-scan.png';
import './style.css'

class PatientInformation extends Component {
  state = {
    patientPosition: '',
    patientOrientation: '',
    positionImage: supineHead,
    seriesName: '',
    accessionNumber: '',
    patientId: '',
    patientName: '',
    patientWeight: '',
    patientInfoGuide: 'block',
    patientInfoFullGuide: 'none'
  };

  patientEntries = [
    { text: 'Head First', value: 'head', id: 1 },
    { text: 'Feet First', value: 'feet', id: 2 },
  ];

  patientPositions = [
    { text: 'Supine', value: 'supine', id: 1 },
    { text: 'Prone', value: 'prone', id: 2 },
  ];

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value }, () => {
      this.setPositionImage();
    });
  };

  handleInputChange = name => event => {
    this.props.scannerAction.newExam();
    this.setState({ [name]: event.target.value }, () => {
      if (this.state.accessionNumber !== '' && this.state.patientId !== '' && this.state.patientName !== '' && this.state.patientWeight !== '') {
        this.props.scannerAction.changeStore('patientInfoGuide', 'none')
        this.props.scannerAction.changeStore('patientInfoFullGuide', 'block')
      }
    });
  }

  setPositionImage = () => {
    const { patientPosition, patientOrientation } = this.state;
    if (patientPosition === 'prone' && patientOrientation === 'head')
      this.setState({ positionImage: proneHead });
    else if (patientPosition === 'prone' && patientOrientation === 'feet')
      this.setState({ positionImage: proneFeet });
    else if (patientPosition === 'supine' && patientOrientation === 'feet')
      this.setState({ positionImage: supineFeet });
    else if (patientPosition === 'supine' && patientOrientation === 'head')
      this.setState({ positionImage: supineHead });
    else if (patientPosition === '' && patientOrientation === '')
      this.setState({ positionImage: defaultImg });
  };

  componentDidUpdate(prevProps) {
    // change patient position and orientation
    if (prevProps.scanner.patientPosition !== this.props.scanner.patientPosition ||
      prevProps.scanner.patientEntry !== this.props.scanner.patientEntry) {
      let patientPosition = this.patientPositions.find(el => el.value === this.props.scanner.patientPosition)
      let patientOrientation = this.patientEntries.find(el => el.value === this.props.scanner.patientEntry)
      this.handleChange(null, {
        name: 'patientPosition',
        value: patientPosition ? patientPosition.value : ''
      });
      this.handleChange(null, {
        name: 'patientOrientation',
        value: patientOrientation ? patientOrientation.value : ''
      });
    }

    if (prevProps.scanner.examEnded !== this.props.scanner.examEnded || prevProps.scanner.isCoil !== this.props.scanner.isCoil) {
      if (this.props.scanner.examEnded) {
        this.setState({
          patientId: '',
          patientName: '',
          positionImage: defaultImg,
          patientWeight: '',
          accessionNumber: '',
        })
      }
      if (this.props.scanner.isCoil) {
        this.setState({
          positionImage: defaultImg
        })
      }
    }
  }

  render() {
    const { isRunning } = this.props.scanner.scan;

    return (
      <Form size="mini">
        <Header
          as="h4"
          content="Patient Information"
          textAlign="center"
        />
        <Form.Input
          name="accessionNumber"
          label="Accession Number"
          readOnly={isRunning}
          value={this.state.accessionNumber}
          onChange={this.handleInputChange('accessionNumber')}
        />
        <Form.Input
          name="patientId"
          label="Patient ID"
          readOnly={isRunning}
          value={this.state.patientId}
          onChange={this.handleInputChange('patientId')}
        />
        <Form.Input
          name="patientName"
          label="Patient Name"
          readOnly={isRunning}
          value={this.state.patientName}
          onChange={this.handleInputChange('patientName')}
        />
        <Form.Input
          name="patientWeight"
          label="Patient Weight"
          readOnly={isRunning}
          value={this.state.patientWeight}
          onChange={this.handleInputChange('patientWeight')}
        />
        <Image src={this.state.positionImage} />
      </Form>
    );
  }
}

const mapStateToProps = ({ scanner }) => ({ scanner });
const mapDispatchToProps = dispatch => ({
  scannerAction: bindActionCreators({ changeStore, newExam }, dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(PatientInformation);
