import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { loginUser, setActiveLogUser } from '../actions';
import { Api } from '../services/api';
import LoginComponent from '../components/login/Login';

class Login extends Component {
  state = {
    image: ''
  }

  async componentDidMount() {
    const response = await Api.background.get();
    if(response.data)
      this.setState({
        image: response.data.imagePath
      })
  }

  onSubmit = async values => {
    const response = await Api.auth.login(values);
    const errorStyle = {
      className: 'error-style',
    }

    if (response.error) {
      toastr.error('Error', response.error, errorStyle);
    } else {
      this.props.userAction.loginUser(response.data);
      const logResponse = await Api.log.create(response.data.user.id);
      if(logResponse.error) {
        toastr.error('Error', response.error);
      } else {
        localStorage.setItem('logUserId', logResponse.data.id);
        this.props.userAction.setActiveLogUser(logResponse.data.id);
      }
      this.props.history.push('/');
    }
  };

  render() {
    return (
      <div style={{
        backgroundImage: `url(${this.state.image})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        // position: 'absolute',
        height: '100vh',
        width: '100%'
      }}>
        <LoginComponent
          onSubmit={this.onSubmit}
          onRegisterClick={this.onRegisterClick}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  userAction: bindActionCreators({ loginUser, setActiveLogUser }, dispatch)
});

export default connect(
  null,
  mapDispatchToProps
)(Login);
