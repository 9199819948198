export const defaultState = [
    {
        name: 'scanPlane',
        type: 'text',
        disabled: false,
        label: 'Scan Plane:',
        value: '',
    },
    {
        name: 'fov',
        disabled: false,
        label: 'FOV',
        value: 0,
        type: "number"
    },
    {
        name: 'frequencyDirection',
        disabled: false,
        label: 'Freq. Dir.',
        value: '',
        type: "number"
    },
    {
        name: 'sliceThickness',
        disabled: false,
        label: 'Slice Thickness',
        value: 0,
        type: "number"
    },
    {
        name: 'sliceSpacing',
        disabled: false,
        label: 'Slice Spacing:',
        value: 0,
        type: "number"
    },
    {
        name: 'tr',
        disabled: false,
        label: 'TR:',
        min: '',
        max: '',
        value: 0,
        type: "number"
    },
    {
        name: 'te',
        disabled: false,
        label: 'TE:',
        min: '',
        max: '',
        value: 0,
        type: "number"
    },
    {
        name: 'nex',
        disabled: false,
        label: 'NEX:',
        value: '',
        type: "number"
    }
]