import BaseApiService from './base';

export class LogApiService extends BaseApiService {
  // eslint-disable-next-line
  constructor() {
    super();
  }

  create(id) {
    return this.request({
      method: 'post',
      url: '/api-v2/user-log',
      data: {
        userId: id,
        appType: 'MRI'
      }
    });
  }

  update(id, data) {
    return this.request({
      method: 'put',
      url: `/user-log/${id}`,
      data
    })
  }

  addProtocol(userActiveLogId, protocol) {
    if(!userActiveLogId) return;

    return this.request({
      method: 'put',
      url: '/api-v2/user-log/addProtocol',
      data: {
        id: userActiveLogId,
        protocol
      }
    })
  }

  addSequence(userActiveLogId, sequence) {
    if(!userActiveLogId) return;

    return this.request({
      method: 'put',
      url: '/api-v2/user-log/addSequence',
      data: {
        id: userActiveLogId,
        sequence
      }
    })
  }
}
