import { createActions } from 'redux-actions';

const LOGIN_USER = 'LOGIN_USER';
const STORE_USER = 'STORE_USER';
const SIGN_OUT = 'SIGN_OUT';
const SET_ACTIVE_LOG_USER = 'SET_ACTIVE_LOG_USER';

const {
  loginUser,
  storeUser,
  setActiveLogUser,
  signOut
} = createActions({
  [LOGIN_USER]: userData => ({ userData }),
  [STORE_USER]: userData => ({ userData }),
  [SET_ACTIVE_LOG_USER]: id => ({ id }),
  [SIGN_OUT]: () => ({})
});

export {
  loginUser,
  LOGIN_USER,
  storeUser,
  STORE_USER,
  setActiveLogUser,
  SET_ACTIVE_LOG_USER,
  signOut,
  SIGN_OUT
};

