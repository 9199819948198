import React from 'react';
import { Popup } from 'semantic-ui-react';
import { connect } from 'react-redux';
import './Tooltip.css';

class Tooltip extends React.PureComponent {
    render() {
        if(this.props.tooltips.data[this.props.paramKey] === undefined) {
            return this.props.trigger
        } else {
            return (
                <Popup 
                    className="tooltip-mri"
                    trigger={this.props.trigger} 
                    content={this.props.tooltips.data[this.props.paramKey]}
                />
            )
        }
        
    }
}

const mapStateToProps = ({ tooltips }) => ({ tooltips });

export default connect(mapStateToProps)(Tooltip);