import React, { Component } from 'react';
import uid from 'uuid';
import { Modal, Grid, Image } from 'semantic-ui-react';

export default class CoilsModal extends Component {

  state = {
    coils: [],
    modalOpen: false
  }

  componentWillReceiveProps(nextProps) {
    const { data, modalOpen} = nextProps;
    this.setState({
      modalOpen,
      coils: data
    });
  }

  render() {
    return (
      <Modal open={this.state.modalOpen} onClose={this.props.onClose} size="small" closeIcon className='coils-modal' closeOnDimmerClick={false}>
        <Modal.Header>Coil Panel</Modal.Header>
        <Modal.Content>
          <p>Please select a coil</p>
          <Grid columns='three'>
              <Grid.Row key={`grid-row-${uid()}`}>
                {this.state.coils.map(element => (
                  <Grid.Column key={`grid-col-${uid()}`} className='coils-each-column'>
                    <Image className="coil-image" src={ element.path } onClick={() => this.props.action(element)}/>
                    <span>{element.name}</span>
                  </Grid.Column>
                ))}
              </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}