import React, { Component } from 'react';
import { Form, Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleProtocolPlace } from '../../actions';

import './style.css';
import Tooltip from '../tooltip/Tooltip';

class SelectedProtocol extends Component {

    state = {
        isCoilSelected: false,
        protocolName: '',
        showModal: false,
    }

    openProtocolPlace = () => {
        if(this.state.isCoilSelected) 
            this.props.scannerAction.toggleProtocolPlace(true);
        else 
            this.setState({showModal: true})
    }

    onClose = () => {
        this.setState({showModal: false})
    }

    componentWillReceiveProps(nextProps) {
        const { selectedCoil, protocol } = nextProps.scanner;
        this.setState({
            protocolName: protocol.name ? protocol.name : '',
            isCoilSelected: selectedCoil.id ? true : false
        });
    }

    render() {
        return (
            <React.Fragment>
                <Form.Group className='protocol-selection'>
                    <Form.Input 
                        readOnly
                        name="protocol"
                        label="Protocol"                      
                        id="protocol-input"
                        value={this.state.protocolName}
                    />
                    <Tooltip paramKey={'protocols'} trigger={
                        <button className='popup-trigger-button' onClick={this.openProtocolPlace}>Protocols</button>
                    } />
                </Form.Group>
                <Modal
                    open={this.state.showModal}
                    className='protocol-confirm-message'>
                    <Modal.Content>
                        <p>Please, select a coil first.</p>
                        <button onClick={this.onClose}>OK</button>
                    </Modal.Content>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({ scanner }) => ({ scanner });
const mapDispatchToProps = dispatch => ({
  scannerAction: bindActionCreators({ toggleProtocolPlace }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectedProtocol);
