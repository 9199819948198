import BaseApiService from './base';

export class AuthApiService extends BaseApiService {
  // eslint-disable-next-line
  constructor() {
    super();
  }

  verifyAuth = () => this.request({
    method: 'get',
    url: '/verifyAuth',
  });

  login = data => this.request({
    method: 'post',
    url: '/login',
    data
  });


  register = data => this.request({
    method: 'post',
    url: '/register',
    data
  });

  restore = data => this.request({
    method: 'post',
    url:'/user/forgotpassword',
    data
  });

  verifyRestore = (token) => this.request({
    method: 'get',
    url: `/user/verifytoken/${token}`,
  });

  changePass = data => this.request({
    method: 'post',
    url: '/user/changepassword',
    data
  });

  logout(customerId, userId, userData) {
    return this.request({
      method: 'post',
      url: '/logout',
      data: {
        customerId,
        userId,
        userData
      },      
    }, true, false);
  }
}
