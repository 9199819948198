import { handleActions } from 'redux-actions';
import {
    CANVAS_UPDATE_GRAPHICRX,
    ENABLE_PLANSLICE,
    SET_ACTIVE_VIEWPORT,
    DISABLE_PLANSLICE,
    SET_DEFAULT,
    ENABLE_SAT,
    CHECK_MODAL,
    RESET_PLANSLICES,
    CHANGE_PLANSLICES_STORE
} from '../actions';

const defaultState = {
    canvas: {
        graphicRx1: null,
        graphicRx2: null,
        graphicRx3: null,
    },
    planeSliceEnable: {
        graphicRx1: true,
        graphicRx2: true,
        graphicRx3: true,
    },
    viewportActive: {
        graphicRx1: false,
        graphicRx2: false,
        graphicRx3: false,
    },
    sat: false,
    wasOpened: false,
    oneActiveVW: '',
    objCoords: {}
}

export default handleActions(
    {
        [CANVAS_UPDATE_GRAPHICRX]: (state, { payload: { name, canvas } }) => {
            const updatedCanvas = {
                ...state.canvas,
                [name]: canvas
            }

            return {
                ...state,
                canvas: updatedCanvas
            }
        },
        [ENABLE_PLANSLICE]: (state, { payload: { id } }) => {
            const updatedCanvas = {
                ...state.planeSliceEnable,
                [id]: true
            }

            return {
                ...state,
                planeSliceEnable: updatedCanvas
            }
        },
        [SET_ACTIVE_VIEWPORT]: (state, { payload: { name } }) => {
            const updatedViewport = {
                graphicRx1: false,
                graphicRx2: false,
                graphicRx3: false,
                [name]: true
            }

            return {
                ...state,
                viewportActive: updatedViewport,
                oneActiveVW: [name]
            }
        },
        [DISABLE_PLANSLICE]: (state, { payload: { id } }) => {
            const updatedCanvas = {
                ...state.planeSliceEnable,
                [id]: false
            }

            return {
                ...state,
                planeSliceEnable: updatedCanvas
            }
        },
        [ENABLE_SAT]: (state, { payload: { isEnabled } }) => {
            return {
                ...state,
                sat: isEnabled
            }
        },
        [CHECK_MODAL]: (state, { payload: { wasOpened } }) => {
            return {
                ...state,
                wasOpened: wasOpened
            }
        },
        [SET_DEFAULT]: state => {
            return {
                ...state,
                canvas: {
                    graphicRX: null,
                    // reconstruction: null
                },
                planeSliceEnable: {
                    graphicRX: false,
                    // reconstruction: false
                },
                sat: false,
            }
        },
        [RESET_PLANSLICES]: state => {
            return defaultState;
        },
        [CHANGE_PLANSLICES_STORE]: (state, { payload: { name, value } }) => {
            return {
                ...state,
                [name]: value
            }
        },
    },
    defaultState
);