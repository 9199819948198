import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Form } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { selectCoil, toggleProtocolPlace, newExam, examStatus, changeStore, endExam } from '../../actions';
import CoilsModal from './CoilsModal';
import Tooltip from '../tooltip/Tooltip'

import './style.css';

class PatientCoils extends Component {

    state = {
        modalOpen: false,
        selectedCoil: {
            name: ''
        }
    };

    handleClose = () => {
        this.setState({ modalOpen: false });
        this.props.scannerAction.changeStore('modalIsOpen', false);
    }


    onCoilClick = selectedCoil => {
        this.props.scannerAction.endExam();
        this.props.scannerAction.selectCoil(selectedCoil);
        this.handleClose();
        this.props.scannerAction.toggleProtocolPlace(true);

        this.props.scannerAction.changeStore('patientInfoGuide', 'none')

    }

    componentWillReceiveProps(nextProps) {
        const { selectedCoil } = nextProps.scanner;

        this.setState({
            selectedCoil
        })
    }

    render() {
        const { isRunning } = this.props.scanner.scan;

        return (
            <React.Fragment>
                <Form.Group className='coil-selection'>
                    <Form.Input
                        name="imagingCoil"
                        label="Coil"
                        readOnly
                        id="coil-input"
                        value={this.state.selectedCoil.name}
                    />
                    <Tooltip paramKey={'coils'} trigger={<button className='coil-trigger-button' onClick={() => {
                        this.setState({ modalOpen: true });
                        this.props.scannerAction.changeStore('modalIsOpen', true);
                    }}>.</button>}
                    />
                </Form.Group>
                <CoilsModal
                    action={this.onCoilClick}
                    onClose={this.handleClose}
                    data={this.props.coils.data.coils}
                    modalOpen={!isRunning && this.state.modalOpen}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ scanner, coils }) => ({ scanner, coils });
const mapDispatchToProps = dispatch => ({
    scannerAction: bindActionCreators({ selectCoil, toggleProtocolPlace, newExam, examStatus, changeStore, endExam }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientCoils)