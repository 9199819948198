import BaseApiService from './base';

export class SeriesApiServices extends BaseApiService {
  // eslint-disable-next-line
  constructor() {
    super();
  }

  getFiles = (sequenceId) => this.request({
    method: 'get',
    url: `api-v2/files/sequence/${sequenceId}`
  });
}
