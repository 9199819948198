import React, { Component } from 'react';
import { Form, Header, Button } from 'semantic-ui-react';

import { changeLocalSerieField } from "../../actions";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";

import './style.css';
import { defaultState } from '../../utils/scanTimingDefaultState';
import Tooltip from '../tooltip/Tooltip';

class ScanTiming extends Component {
	state = {
		scanTimings: []
	};

	handleChange(value, name) {
		this.props.scannerAction.changeLocalSerieField({
			[name]: value
		});
	}

	componentDidMount() {
		this.setState({ scanTimings: defaultState })
	}

	componentWillReceiveProps(nextProps) {
		const { scanTimings } = this.state;
		const { examEnded } = nextProps.scanner;
		for (let i = 0; i < scanTimings.length; i++) {
			const element = scanTimings[i];
			if (nextProps.localSerie[element.name] !== null && nextProps.localSerie[element.name] !== undefined) {
				element.value = nextProps.localSerie[element.name];

				let elName = element.name;
				let titleChart = elName[0].toUpperCase() + elName.slice(1);

				element.min = nextProps.localSerie['min' + titleChart];
				element.max = nextProps.localSerie['max' + titleChart];
			} else {
				element.value = 0;
				element.max = '';
				element.min = '';
			}
		}

		this.setState({
			scanTimings: [...scanTimings]
		});

		if (examEnded) {
			this.setState({ scanTimings: defaultState })
		}

	}

	render() {

		return (
			<div className="scan-timing">
				<Header
					as="h4"
					content="Scan Timing"
					textAlign="center"
				/>
				<div className="scan-timing-table">
					<div className="scan-timing-table-row">
						<div className='scan-timing-table-coll-input-label'>
						</div>
						<div className="scan-timing-table-coll-input">
						</div>
						<div className="scan-timing-table-coll-button">
						</div>
						<div className="scan-timing-table-coll">
							Min
						</div>
						<div className="scan-timing-table-coll">
							Max
						</div>
					</div>
					{this.state.scanTimings.map((item, index) => {
						return (
							<div key={index} className={item.disabled ? "scan-timing-table-row disabled-row" : 'scan-timing-table-row'}>
								<div className='scan-timing-table-coll-input-label'>{item.label}</div>
								<div className="scan-timing-table-coll-input">
									<Form.Input
										name={item.name}
										disabled={item.disabled}
										onChange={e => this.handleChange(e.target.value, item.name)}
										value={item.value}
										type="number"
										min={item.min}
										max={item.max}
									/>
								</div>
								<div className="scan-timing-table-coll-button">
									<Tooltip
										trigger={<Button id={item.name === 'tez' ? 'tez-button' : null} />}
										paramKey={item.name}
										key={index}
									>
									</Tooltip>
								</div>
								<div className="scan-timing-table-coll">
									{item.min}
								</div>
								<div className="scan-timing-table-coll">
									{item.max}
								</div>
							</div>
						)
					})}
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ scanner }) => ({ localSerie: scanner.localSerie, scanner });
const mapDispatchToProps = dispatch => ({
	scannerAction: bindActionCreators({ changeLocalSerieField }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ScanTiming);
