import wristCoilImg from '../../images/coilImages/WristCoil.jpg';
import torsoCoilImg from '../../images/coilImages/TorsoCoil.jpg';
import brainCoilImg from '../../images/coilImages/BrainCoil.jpg';
import ctlSpineCoilImg from '../../images/coilImages/CTLSpineCoil.jpg';
import shoulderCoilImg from '../../images/coilImages/ShoulderCoil.jpg';
import kneeFootCoilImg from '../../images/coilImages/KneeFootCoil.jpg';

export default [[
    {
        id: "wristCoil",
        name:"Wrist Coil",
        path: wristCoilImg
    },
    {
        id: "ctlSpineCoil",
        name:"CTL Spine Coil",
        path: ctlSpineCoilImg
    },
    {
        id: "shoulderCoil",
        name:"Shoulder Coil",
        path: shoulderCoilImg
    }
], [
    {
        id: "kneeFootCoil",
        name:"Knee/Foot/Ankle Coil",
        path: kneeFootCoilImg
    },
    {
        id: "torsoCoil",
        name:"Torso Coil",
        path: torsoCoilImg
    },
    {
        id: "brainCoil",
        name:"Brain Coil",
        path: brainCoilImg
    }
]];
