import { createActions } from 'redux-actions';

const SAVE_COILS = 'SAVE_COILS';

const {
    saveCoils
} = createActions ({
    [SAVE_COILS]: coils => coils,
})

export {
    saveCoils,
    SAVE_COILS,
};