import { handleActions } from 'redux-actions';
import {
  END_EXAM,
  STOP_SCAN,
  START_SCAN,
  CANCEL_SCAN,
  SELECT_COIL,
  STORE_SERIES,
  SELECT_ACTIVE_SERIE,
  STORE_SERIES_FILES,
  STORE_MIP_SERIES_FILES,
  UPDATE_ACTIVE_SERIES,
  TOGGLE_PROTOCOL_PLACE,
  SELECT_PREVIOUS_SERIES,
  CHANGE_LOCAL_SERIE_FIELD,
  UPDATE_PRESSURE_INJECTOR,
  SYNC_SERIES,
  PREPARE_SCAN,
  CHANGE_ENABLED_START_SCAN,
  EXAM_STATUS,
  NEW_EXAM,
  IMAGE_STEP,
  STORE_SEQUENCE,
  CHANGE_STORE,
  NEW_LOCAL_SERIE_ID,
} from '../actions';
import coils from './data/coils';

const defaultState = {
  patientPosition: '',
  patientEntry: '',
  coils,
  series: [],
  mipSeries: [],
  protocol: {},
  activeSerie: {},
  localSerie: {},
  selectedCoil: { name: '' },
  hasNextSeries: false,
  openedProtocol: false,
  hasPreviousSeries: false,
  currentSeriesIndex: 0,
  prapereToScan: false,
  enableSaveSeries: false,
  changedSerie: false,
  enableStartScan: false,
  files: {
    images: [],
    sounds: []
  },
  scan: {
    cancelled: false,
    isRunning: false
  },
  examEnded: false,
  isCoil: false,
  imageStep: 0,
  imageStopped: false,
  sequenceNumber: 0,
  sequenceState: [{}],
  activeSequence: 0,
  modalIsOpen: false,
  brightness: 100,
  contrast: 100,
  opacity: 0,
  eraseGroup: true,
  eraseAll: true,
  changeAngle: true,
  patientInfoGuide: 'block',
  patientInfoFullGuide: 'none',
  saveSeriesGuide: 'none',
  prepareScanGuide: 'none',
  scanButtonGuide: 'none',
  newLocalSerieId: {
    id: [],
    images: [],
  },
  newLocalSerieIdImages: {
    images: [],
  },
  showAutoview: false,
  fatWaterField: '',
};

export default handleActions(
  {
    [CHANGE_ENABLED_START_SCAN]: (state, { payload }) => {
      return {
        ...state,
        enableStartScan: payload,
        prapereToScan: false,
      }
    },
    [CHANGE_LOCAL_SERIE_FIELD]: (state, { payload: { fields } }) => {
      return {
        ...state,
        enableSaveSeries: true,
        changedSerie: true,
        localSerie: {
          ...state.localSerie,
          ...fields
        },
      }
    },
    [SYNC_SERIES]: (state) => {
      return {
        ...state,
        activeSerie: {
          ...state.localSerie
        }
      }
    },
    [PREPARE_SCAN]: (state) => {
      return {
        ...state,
        prapereToScan: true,
        enableSaveSeries: false,
        changedSerie: false,
      }
    },
    [STORE_SERIES]: (state, { payload: { protocol, series, mipSeries, isProtocolOpen } }) => {
      const hasNextSeries = series.length > 1;

      return {
        ...state,
        series,
        mipSeries,
        protocol,
        hasNextSeries,
        currentSeriesIndex: 0,
        hasPreviousSeries: false,
        openedProtocol: isProtocolOpen
      };
    },
    [SELECT_ACTIVE_SERIE]: (state, { payload: { data } }) => {

      const activeSerie = {
        ...data,
      };

      return {
        ...state,
        activeSerie,
        localSerie: activeSerie,
        enableSaveSeries: true,
        changedSerie: true,
        isCoil: false,
      };
    },
    [SELECT_PREVIOUS_SERIES]: state => {
      // decrease current series index by 1
      const currentSeriesIndex = state.currentSeriesIndex - 1;
      const activeSerie = state.series[currentSeriesIndex];
      const hasPreviousSeries = currentSeriesIndex !== 0;

      return {
        ...state,
        activeSerie,
        currentSeriesIndex,
        hasNextSeries: true,
        hasPreviousSeries
      };
    },
    [UPDATE_ACTIVE_SERIES]: (state, { payload: { field, value } }) => {
      const activeSerie = { ...state.activeSerie, [field]: value };
      return { ...state, activeSerie };
    },
    [UPDATE_PRESSURE_INJECTOR]: (state, { payload: { field, value } }) => {
      const pressureInjector = { ...state.pressureInjector, [field]: value };

      return { ...state, pressureInjector };
    },
    [START_SCAN]: state => {
      const scan = { ...state.scan, isRunning: true, cancelled: false };

      return {
        ...state,
        prapereToScan: false,
        imageStep: 0,
        imageStopped: false,
        scan
      };
    },
    [STOP_SCAN]: state => {
      const scan = { ...state.scan, isRunning: false, cancelled: false };

      return {
        ...state,
        scan,
        enableSaveSeries: true,
      };
    },
    [CANCEL_SCAN]: state => {
      const scan = { ...state.scan, isRunning: false, cancelled: true };

      return {
        ...state,
        scan
      };
    },
    [SELECT_COIL]: (state, { payload: { selectedCoil } }) => {
      return {
        ...state,
        selectedCoil,
        isCoil: true,
      };
    },
    [END_EXAM]: state => {
      // return defaultState;
      return { ...defaultState, examEnded: true };
    },
    [STORE_SERIES_FILES]: (state, { payload: { serie } }) => {
      const files = { images: serie.images, sounds: serie.sounds };
      return {
        ...state,
        files
      };
    },
    [STORE_MIP_SERIES_FILES]: (state, { payload: { serie } }) => {
      const newLocalSerieIdImages = { images: serie.images };

      return {
        ...state,
        newLocalSerieIdImages
      };
    },
    [TOGGLE_PROTOCOL_PLACE]: (state, { payload: { isOpened } }) => {
      return {
        ...state,
        openedProtocol: isOpened
      };
    },
    [EXAM_STATUS]: (state, { payload: { status, isCoil } }) => {
      return {
        ...state,
        examEnded: status,
        // isCoil: isCoil,
      };
    },
    [NEW_EXAM]: state => {
      return {
        ...state,
        examEnded: false,
        // isCoil: false,
      };
    },
    [IMAGE_STEP]: (state, { payload: { i } }) => {
      return {
        ...state,
        imageStep: i,
        imageStopped: true
      }
    },
    [STORE_SEQUENCE]: (state, { payload: { i, id } }) => {
      return {
        ...state,
        sequenceNumber: i,
        sequenceState: [...state.sequenceState, { text: i, value: id }],
      }
    },
    [CHANGE_STORE]: (state, { payload: { name, value } }) => {
      return {
        ...state,
        [name]: value
      }
    },
    [NEW_LOCAL_SERIE_ID]: (state, { payload: { data } }) => {
      return {
        ...state,
        newLocalSerieId: data
      }
    }
  },
  defaultState
);
