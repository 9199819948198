import BaseApiService from './base';

export class CoilsApiService extends BaseApiService {
    // eslint-disable-next-line
    constructor() {
        super();
    }

    getAllCoils() {
        return this.request({
          method: 'get',
          url: '/api-v2/coils',
        });
    }
}