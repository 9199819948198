import { createActions } from 'redux-actions';

const CANVAS_UPDATE_GRAPHICRX = 'CANVAS_UPDATE_GRAPHICRX';
const ENABLE_PLANSLICE = 'ENABLE_PLANSLICE';
const SET_ACTIVE_VIEWPORT = 'SET_ACTIVE_VIEWPORT';
const DISABLE_PLANSLICE = 'DISABLE_PLANSLICE';
const SET_DEFAULT = 'SET_DEFAULT';
const ENABLE_SAT = 'ENABLE_SAT';
const CHECK_MODAL = 'CHECK_MODAL';
const RESET_PLANSLICES = 'RESET_PLANSLICES';
const CHANGE_PLANSLICES_STORE = 'CHANGE_PLANSLICES_STORE'

const {
  canvasUpdateGraphicrx,
  enablePlanslice,
  setActiveViewport,
  disablePlanslice,
  setDefault,
  enableSat,
  checkModal,
  resetPlanslices,
  changePlanslicesStore
} = createActions({
  [CANVAS_UPDATE_GRAPHICRX]: (name, canvas) => ({ name, canvas }),
  [ENABLE_PLANSLICE]: id => ({ id }),
  [SET_ACTIVE_VIEWPORT]: name => ({ name }),
  [DISABLE_PLANSLICE]: id => ({ id }),
  [SET_DEFAULT]: () => ({}),
  [ENABLE_SAT]: isEnabled => ({ isEnabled }),
  [CHECK_MODAL]: wasOpened => ({ wasOpened }),
  [RESET_PLANSLICES]: () => ({}),
  [CHANGE_PLANSLICES_STORE]: (name, value) => ({ name, value }),
});

export {
  canvasUpdateGraphicrx,
  CANVAS_UPDATE_GRAPHICRX,
  enablePlanslice,
  ENABLE_PLANSLICE,
  setActiveViewport,
  SET_ACTIVE_VIEWPORT,
  disablePlanslice,
  DISABLE_PLANSLICE,
  setDefault,
  SET_DEFAULT,
  enableSat,
  ENABLE_SAT,
  checkModal,
  CHECK_MODAL,
  resetPlanslices,
  RESET_PLANSLICES,
  changePlanslicesStore,
  CHANGE_PLANSLICES_STORE
};
