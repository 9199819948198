import { handleActions } from 'redux-actions';
import {
    SET_COORDS
} from '../actions';

const defaultState = {
    objCoords: {}
}

export default handleActions(
    {
        [SET_COORDS]: (state, { payload: { coords } }) => {
            return {
                ...defaultState,
                objCoords: coords
            }
        },
    },
    defaultState
);