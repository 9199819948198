export const defaultState = [
    {
        name: 'fov',
        disabled: false,
        label: 'FOV',
        value: 0,
        withButton: true,
        popup: true
    }, {
        name: 'sliceThickness',
        disabled: true,
        label: 'Slice Thickness',
        value: 0,
        withButton: true,
        popup: true
    }, {
        name: 'sliceSpacing',
        disabled: true,
        label: 'Slice Spacing',
        value: 0,
        withButton: true,
        popup: true
    }, {
        name: 'startLocation',
        disabled: true,
        label: 'Start',
        value: 0
    }, {
        name: 'endLocation',
        disabled: true,
        label: 'End',
        value: 0
    }, {
        name: 'numberSlices',
        disabled: true,
        label: 'Number of Slices',
        value: 0
    }
];
export const maxFov = '';
export const minFov = '';
